import { MutableRefObject, useEffect, useRef } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { TaskStep } from "@/containers/Video/types";
import { isDevelopment, nl2br, noop, usePrevious } from "../../helpers";
import { toHHMMSS } from "../../helpers-ts";

import styles from "./styles.module.css";

/** The % visibility required for the active element
  to be considered visible. Mapped to 0.0 - 1.0 */
const ACTIVE_ITEM_VISIBILITY = 0;

export interface TimelineItemProps {
  step: TaskStep;
  currentVideoTime: number;
  videoDuration: number;
  prevStep: TaskStep;
  isLegacyTask: boolean;
  onClickTime: (timestamp: number) => void;
  onClickDownloadVideoClip?: (
    timestampStart: number,
    timestampEnd: number,
  ) => void;
  scrollerRef: MutableRefObject<HTMLDivElement | null>;
  preventScrollIntoView: boolean;
  onChangeCurrentTimelineItemVisible: (isVisible: boolean) => void;
}

export function TimelineItem({
  step,
  currentVideoTime,
  videoDuration,
  prevStep,
  isLegacyTask,
  onClickTime,
  onClickDownloadVideoClip,
  scrollerRef,
  preventScrollIntoView,
  onChangeCurrentTimelineItemVisible,
}: TimelineItemProps) {
  let timestampStart = step.timestampStart;

  // if video is already over don’t show timestamp
  if (prevStep?.videoEndsAfterThisStep) {
    timestampStart = 1000000;
  }

  const timestampEnd = step.timestampCompleted;
  const hasValidTimestamp = timestampStart < 1000000;

  const itemRef = useRef<HTMLDivElement | null>(null);
  const isCurrent = !!(
    (currentVideoTime >= timestampStart &&
      currentVideoTime < timestampEnd &&
      currentVideoTime < videoDuration) ||
    (isLegacyTask && currentVideoTime < videoDuration)
  );
  const isPast =
    currentVideoTime >= timestampEnd ||
    (currentVideoTime >= videoDuration && hasValidTimestamp) ||
    (isLegacyTask && currentVideoTime >= videoDuration);
  const prevIsCurrent = usePrevious(isCurrent);

  // Intersection observer that tests if the current item
  // and the passed scrollContainer intersect.
  useEffect(() => {
    if (isCurrent && scrollerRef?.current && itemRef.current) {
      const intersectionObserver = new IntersectionObserver(
        (args) => {
          const isIntersect = args?.[0]?.isIntersecting;
          onChangeCurrentTimelineItemVisible(!!isIntersect);
        },
        {
          threshold: ACTIVE_ITEM_VISIBILITY,
          root: scrollerRef.current,
        },
      );
      intersectionObserver.observe(itemRef.current);
      return () => intersectionObserver.disconnect();
    }
  }, [
    isCurrent,
    scrollerRef,
    onChangeCurrentTimelineItemVisible,
    currentVideoTime,
  ]);

  // Scroll to current item

  useEffect(() => {
    const isMobilePlayer =
      itemRef.current &&
      window
        ?.getComputedStyle(itemRef.current)
        .getPropertyValue("--is-mobile-player") === "1";

    const fireScrollIntoView =
      isCurrent === true &&
      prevIsCurrent !== true &&
      !isLegacyTask &&
      !isMobilePlayer &&
      !preventScrollIntoView;

    if (fireScrollIntoView) {
      itemRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isCurrent, prevIsCurrent, isLegacyTask, preventScrollIntoView]);

  function handleClickTime() {
    onClickTime(timestampStart);
  }

  function handleClickDownload() {
    onClickDownloadVideoClip?.(
      timestampStart,
      Math.min(videoDuration, timestampEnd),
    );
  }

  let content;
  switch (step.type) {
    case "transcript":
      // I KNOW THIS IS NOT A TASK STEP BUT HAVE TO MOVE FAST…
      // Todo: Refactor
      content = (
        <>
          <div className={styles.task}>{nl2br(step.transcript)}</div>
        </>
      );
      break;
    case "task":
      const isCompleted = step.response?.completed;
      // const askCompleted = step.options.ask_completed;
      content = (
        <>
          {isCompleted === true && (
            <div className={styles.title}>
              <FontAwesomeIcon fixedWidth icon={regular("check")} /> Task
              completed
            </div>
          )}
          {isCompleted === false && (
            <div className={styles.title}>
              <FontAwesomeIcon fixedWidth icon={regular("times")} /> Task failed
            </div>
          )}
          {isCompleted !== true && isCompleted !== false && (
            <div className={styles.title}>Task</div>
          )}

          <div className={styles.task}>{nl2br(step.task)}</div>
        </>
      );
      break;
    case "single_choice":
      content = (
        <>
          <div className={styles.title}>Single Choice</div>
          <div className={styles.task}>{nl2br(step.task)}</div>
          {step.response && (
            <div className={styles.response}>
              {step.options.answers?.map((answer, answerIndex) => (
                <div key={answerIndex}>
                  {step.response?.choice === answerIndex ? (
                    <FontAwesomeIcon fixedWidth icon={regular("dot-circle")} />
                  ) : (
                    <FontAwesomeIcon fixedWidth icon={regular("circle")} />
                  )}
                  <> {answer}</>
                </div>
              ))}
              {typeof step.response?.choice === "undefined" && (
                <>
                  {step.response?.skipped === true ? (
                    <div className={styles.responseSkipped}>
                      The tester could not answer this question.
                    </div>
                  ) : (
                    <div className={styles.responseSkipped}>
                      The tester did not answer this question.
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      );
      break;
    case "multiple_choice":
      content = (
        <>
          <div className={styles.title}>Multiple Choice</div>
          <div className={styles.task}>{nl2br(step.task)}</div>
          {step.response && (
            <div className={styles.response}>
              {step.options.answers?.map((answer, answerIndex) => (
                <div key={answerIndex}>
                  {Array.isArray(step.response?.choice) &&
                  step.response?.choice.indexOf(answerIndex) !== -1 ? (
                    <FontAwesomeIcon
                      fixedWidth
                      icon={regular("check-square")}
                    />
                  ) : (
                    <FontAwesomeIcon fixedWidth icon={regular("square")} />
                  )}
                  <> {answer}</>
                </div>
              ))}
              {typeof step.response?.choice === "undefined" && (
                <>
                  {step.response?.skipped === true ? (
                    <div className={styles.responseSkipped}>
                      The tester could not answer this question.
                    </div>
                  ) : (
                    <div className={styles.responseSkipped}>
                      The tester did not answer this question.
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      );
      break;
    case "rating_scale":
      content = (
        <>
          <div className={styles.title}>Rating Scale</div>
          <div className={styles.task}>{nl2br(step.task)}</div>
          {step.response && (
            <div className={styles.response}>
              Rating: {step.response.rating}
            </div>
          )}
        </>
      );
      break;
    case "redirect":
      content = (
        <>
          <div className={styles.title}>Redirect</div>
          <div className={styles.task}>
            {nl2br(step.task)}
            <br />
            <br />
            {step.options.url}
          </div>
        </>
      );
      break;
    case "written_response":
      content = (
        <>
          <div className={styles.title}>Written Response</div>
          <div className={styles.task}>Q: {nl2br(step.task)}</div>
          {step.response && (
            <div className={styles.response}>
              A: {nl2br(step.response.response)}
            </div>
          )}
        </>
      );
      break;
    default:
      content = (
        <>
          <div className={styles.title}>{step.type} (not implemented)</div>
          <div className={styles.task}>{nl2br(step.task)}</div>
          <div className={styles.response}>{JSON.stringify(step.response)}</div>
        </>
      );
  }

  return (
    <>
      <div
        className={cn(
          styles.item,
          step.type === "transcript" && styles.item_transcript,
          isCurrent && styles.current,
          isPast && styles.past,
        )}
        ref={itemRef}
      >
        <div
          className={cn(
            styles.time,
            hasValidTimestamp && styles.timeWithValidTimestamp,
          )}
          onClick={hasValidTimestamp ? handleClickTime : noop}
        >
          {hasValidTimestamp && (
            <div className={styles.timeContent}>
              {toHHMMSS(timestampStart)}
              <FontAwesomeIcon
                fixedWidth
                icon={solid("play")}
                className={styles.playIcon}
              />
            </div>
          )}
        </div>
        <div className={styles.content}>
          {content}
          {typeof onClickDownloadVideoClip === "function" && isDevelopment && (
            <button
              className={styles.downloadClip}
              onClick={handleClickDownload}
            >
              Download
            </button>
          )}
        </div>
      </div>
      {step.videoEndsAfterThisStep && (
        <div
          className={cn(
            styles.item,
            currentVideoTime >= videoDuration && styles.current,
          )}
        >
          <div className={styles.time}>
            <div className={styles.timeContent}>{toHHMMSS(videoDuration)}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Recording end</div>
          </div>
        </div>
      )}
    </>
  );
}
