import { cn } from "@sys42/utils";

import {
  SvgIconHappyEmotion,
  SvgIconNeutralEmotion,
  SvgIconSadEmotion,
} from "./icons";

import styles from "./styles.module.css";

export function AiTaskCreatorFeedback({
  urlSadEmotion,
  urlNeutralEmotion,
  urlHappyEmotion,
  className,
}: {
  className?: string;
  urlSadEmotion: string;
  urlNeutralEmotion: string;
  urlHappyEmotion: string;
}) {
  return (
    <div className={cn(className, styles.aiTaskCreatorFeedback)}>
      <div className={styles.title}>
        What do you think about these auto-created tasks?
      </div>
      <div className={styles.buttons}>
        <a
          className={cn(styles.button, styles.button_sad)}
          href={urlSadEmotion}
          target="_blank"
          rel="noreferrer"
        >
          <SvgIconSadEmotion />
        </a>
        <a
          className={cn(styles.button, styles.button_neutral)}
          href={urlNeutralEmotion}
          target="_blank"
          rel="noreferrer"
        >
          <SvgIconNeutralEmotion />
        </a>
        <a
          className={cn(styles.button, styles.button_happy)}
          href={urlHappyEmotion}
          target="_blank"
          rel="noreferrer"
        >
          <SvgIconHappyEmotion />
        </a>
      </div>
    </div>
  );
}
