import { ChangeEvent } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import { Draggable } from "react-beautiful-dnd";

import { DropDown, DropDownItem, InputText } from "@/design-system";
import { getWordingForScreenerChoice } from "../../../helpers";
import { Answer, Question } from "../types";

import styles from "./styles.module.css";

interface ScreenerChoiceAnswerProps {
  className?: string;
  answer?: Answer;
  onChange: (answer: Answer) => void;
  index: number;
  questionIndex: number;
  placeholder?: string;
  choiceType: Question["type"];
  onClickDelete?: () => void;
  error?: string;
  isAddAnswer: boolean;
}

export default function ScreenerChoiceAnswer({
  className,
  answer,
  onChange,
  index,
  questionIndex,
  placeholder,
  choiceType,
  onClickDelete,
  error,
  isAddAnswer,
}: ScreenerChoiceAnswerProps) {
  function handleChangeText(e: ChangeEvent<HTMLInputElement>) {
    if (isAddAnswer) {
      onChange({
        text: e.target.value,
        typeMultipleChoice: "may_select",
        typeSingleChoice: "accept",
      });
    } else if (answer) {
      onChange({ ...answer, text: e.target.value });
    }
  }

  function handleChangeType(e: ChangeEvent<HTMLSelectElement>) {
    if (answer) {
      if (choiceType === "single_choice") {
        onChange({
          ...answer,
          // TODO - improve types
          typeSingleChoice: e.target.value as Answer["typeSingleChoice"],
        });
      } else {
        onChange({
          ...answer,
          typeMultipleChoice: e.target.value as Answer["typeMultipleChoice"],
        });
      }
    }
  }

  return (
    <Draggable
      key={`${index}`}
      draggableId={`${questionIndex}-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          className={cn(className, styles.choiceAnswer)}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className={cn(styles.handle, isAddAnswer && styles.noHandle)}
            {...provided.dragHandleProps}
            tabIndex={-1}
          >
            <FontAwesomeIcon icon={solid("grip-vertical")} />
          </div>
          <InputText
            className={styles.input}
            value={isAddAnswer ? "" : answer?.text}
            error={error}
            onChange={handleChangeText}
            placeholder={placeholder}
          />
          {!isAddAnswer && (
            <DropDown
              onChange={handleChangeType}
              value={
                choiceType === "single_choice"
                  ? answer?.typeSingleChoice
                  : answer?.typeMultipleChoice
              }
              className={styles.dropDown}
            >
              {choiceType === "single_choice" ? (
                <DropDownItem value={"accept"}>
                  {getWordingForScreenerChoice("accept", choiceType)}
                </DropDownItem>
              ) : (
                [
                  <DropDownItem value={"may_select"} key={"may_select"}>
                    {getWordingForScreenerChoice("may_select", choiceType)}
                  </DropDownItem>,
                  <DropDownItem value={"must_select"} key={"must_select"}>
                    {getWordingForScreenerChoice("must_select", choiceType)}
                  </DropDownItem>,
                ]
              )}
              <DropDownItem value={"reject"}>
                {getWordingForScreenerChoice("reject", choiceType)}
              </DropDownItem>
            </DropDown>
          )}
          <div className={styles.deleteButtonWrapper}>
            <button
              className={cn(
                styles.deleteButton,
                !onClickDelete && styles.noDeleteButton,
              )}
              type={"button"}
              onClick={onClickDelete}
              disabled={!onClickDelete}
              tabIndex={-1}
            >
              <FontAwesomeIcon icon={solid("times")} />
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
}
