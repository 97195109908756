import { useCallback, useMemo, useRef, useState } from "react";
import { cn } from "@sys42/utils";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { InlineIcon } from "@/design-system";
import { useFeatures } from "@/hooks/useFeatures";
import { usePersistentState } from "@/hooks/usePersistentState";
import { TabContentScroller, TabList, Tabs } from "../components/VideoTabs";
import { SvgIconLocked } from "../icons";
import { TaskStep, Video, VideoTabName } from "../types";

import { Details } from "./Details";
import { Menu } from "./Menu";
import { NotesAndClips } from "./Notes";
import { AddNoteOrClip } from "./Notes/Add";
import { NoteType } from "./Notes/types";
import { filterAndCountNotesAndClipsByType } from "./Notes/utils/filterAndCountApiNotesByType";
import { Task } from "./Task";
import Transcript from "./Transcript";
import TranscriptPromotion from "./Transcript/Promotion";

import videoStyles from "../styles.module.css";
import styles from "./styles.module.css";

/**
 *
 * Sidebar
 */
export function Sidebar({
  video,
  isPlaying,
  currentVideoSecond,
  taskStepsWithResponse,
  isLegacyTask,
  onClickTogglePlay,
  onClickTime,
  onClickTimeRange,
  onBack,
  onClickPlay,
  onClickPause,
  videoDuration,
  highlightedNotesAndClipsItem,
}: {
  video: Video;
  isPlaying: boolean;
  currentVideoSecond: number;
  taskStepsWithResponse: TaskStep[];
  isLegacyTask: boolean;
  onClickTogglePlay: () => void;
  onClickTime: (timestamp: number) => void;
  onClickTimeRange: (timestamp: number, timestampEnd: number) => void;
  onBack: () => void;
  onClickPlay: () => void;
  onClickPause: () => void;
  videoDuration: number;
  highlightedNotesAndClipsItem: { type: NoteType; id: number } | null;
}) {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const features = useFeatures();

  const [sidebar, setSidebar] = useState(() => {
    // Open notes tab if notes or note or clip is set in url search string
    if (
      searchParams.get("notes") != null ||
      searchParams.get("note") != null ||
      searchParams.get("clip") != null
    ) {
      return VideoTabName.Notes;
    } else {
      return VideoTabName.Details;
    }
  });
  const [notesFilteredType, setNotesFilteredTypes] = useState<NoteType | null>(
    null,
  );

  const videoHasNotesOrClips =
    Boolean(video.notes?.length) || Boolean(video.clips?.length);

  const {
    notesAndClips: filteredNotesAndClips,
    count: filteredNotesAndClipsCount,
  } = filterAndCountNotesAndClipsByType({
    notes: video.notes,
    clips: video.clips,
    type: notesFilteredType,
  });

  const isTranscriptIncludedInPlan = features?.transcripts;

  const isAiIncludedInPlan = features?.ai;

  const [isCurrentTranscriptItemVisible, setIsCurrentTranscriptItemVisible] =
    useState(true);
  const [isCurrentTaskItemVisible, setIsCurrentTaskItemVisible] =
    useState(true);

  const taskScrollerRef = useRef<HTMLDivElement | null>(null);
  const transcriptScrollerRef = useRef<HTMLDivElement | null>(null);
  const refAddNoteNoteTextarea = useRef<HTMLTextAreaElement | null>(null);

  const isAiAvailableOrCompleteButNotIncludedInPlan =
    (video.ai_status === "available" || video.ai_status === "complete") &&
    !isAiIncludedInPlan;

  const handleChangeCurrentTranscriptItemVisible = useCallback(
    (isVisible: boolean) => {
      setIsCurrentTranscriptItemVisible(isVisible);
    },
    [],
  );

  function resetNotesFilterType() {
    setNotesFilteredTypes(null);
  }

  function handleClickNoteType(clickedType: NoteType) {
    if (clickedType === notesFilteredType) {
      resetNotesFilterType();
    } else {
      setNotesFilteredTypes(clickedType);
    }
  }

  function handleClickShowAllNoteFilterType() {
    resetNotesFilterType();
  }

  const handleChangeCurrentTaskItemVisible = useCallback(
    (isVisible: boolean) => {
      setIsCurrentTaskItemVisible(isVisible);
    },
    [],
  );

  const automatedInsightsCount = useMemo(() => {
    return (
      video?.notes?.filter((note) => note.automated_insight === true).length ??
      0
    );
  }, [video?.notes]);

  useHotkeys("n", (e) => {
    e.preventDefault();
    refAddNoteNoteTextarea.current?.focus();
  });

  const [isDisplayAutomatedInsights, setShowAutomatedInsights] =
    usePersistentState("video-player-show-automated-insights", true);

  const showGenerateAutomatedInsights =
    isAiIncludedInPlan &&
    (video.ai_status === "available" || video.ai_status === "processing");

  function handleNoteSaveRequested() {
    // TODO: maybe this should be called after the note request is done?
    setSidebar(VideoTabName.Notes);
  }

  const handleClickToggleDisplayAutomatedInsights = useCallback(() => {
    setShowAutomatedInsights(!isDisplayAutomatedInsights);
  }, [isDisplayAutomatedInsights, setShowAutomatedInsights]);

  function handleClickUpgrade() {
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
      modalOptions: { redirectToBillingAfterCheckout: true },
    });
  }

  function handleClickDownloadVideoClip(
    timestampStart: number,
    timestampEnd: number,
  ) {
    dispatch({
      type: "DOWNLOAD_VIDEO_CLIP_REQUEST",
      videoId: video?.id,
      timestampStart,
      timestampEnd,
    });
  }

  const [isUpgradeForAiInsightsBoxHidden, setIsUpgradeForAiInsightsBoxHidden] =
    usePersistentState("video-player-upgrade-for-ai-dismissed", false);

  function handleClickHideAiPromotion() {
    setIsUpgradeForAiInsightsBoxHidden(true);
  }

  const notesTabLabel = (
    <>
      Notes & Clips
      {((video.ai_status === "available" && isAiIncludedInPlan) ||
        (video.ai_status === "available" &&
          !isAiIncludedInPlan &&
          !isUpgradeForAiInsightsBoxHidden)) && (
        <div className={videoStyles.tabButtonAiAvailable}>
          Automated Insights available
        </div>
      )}
    </>
  );
  const transcriptTabLabel = (
    <>
      Transcript
      {!isTranscriptIncludedInPlan && (
        <InlineIcon Svg={SvgIconLocked} className={videoStyles.iconLocked} />
      )}
    </>
  );

  return (
    <Tabs value={sidebar} onChange={setSidebar} className={videoStyles.tabs}>
      <TabList
        endComponent={
          <Menu
            video={video}
            automatedInsights={{
              count: automatedInsightsCount,
              onClickToggleDisplay: handleClickToggleDisplayAutomatedInsights,
              isDisplay: isDisplayAutomatedInsights,
            }}
          />
        }
        tabs={[
          { value: VideoTabName.Details, label: "Tester" },
          { value: VideoTabName.Task, label: "Tasks" },
          { value: VideoTabName.Notes, label: notesTabLabel },
          { value: VideoTabName.Transcript, label: transcriptTabLabel },
        ]}
      />

      <TabContentScroller
        value={VideoTabName.Details}
        className={videoStyles.sidebarContent}
      >
        <Details video={video} onBack={onBack} />
      </TabContentScroller>

      <TabContentScroller
        value={VideoTabName.Notes}
        className={cn(videoStyles.sidebarContent, styles.sidebarContentNotes)}
      >
        <NotesAndClips
          classNameNotesListItem={styles.listItem}
          videoId={video.id}
          videoDuration={videoDuration}
          currentVideoSecond={currentVideoSecond}
          isDisplayPlaceholder={!videoHasNotesOrClips}
          isDisplayFilters={
            !!filteredNotesAndClipsCount.clip &&
            !!filteredNotesAndClipsCount.note
          }
          notesAndClips={filteredNotesAndClips}
          notesAndClipsCount={filteredNotesAndClipsCount}
          videoAiStatus={video.ai_status}
          onClickTime={onClickTime}
          onClickTimeRange={onClickTimeRange}
          showPromotion={
            isAiAvailableOrCompleteButNotIncludedInPlan &&
            !isUpgradeForAiInsightsBoxHidden
          }
          showShowAutomatedInsightsButton={
            video.ai_status === "complete" &&
            automatedInsightsCount > 0 &&
            isDisplayAutomatedInsights === false
          }
          showGenerateAutomatedInsights={showGenerateAutomatedInsights}
          automatedInsightsCount={automatedInsightsCount}
          showAutomatedInsights={isDisplayAutomatedInsights}
          onClickUpgrade={handleClickUpgrade}
          onClickHideAiPromotion={handleClickHideAiPromotion}
          onClickToggleAutomatedInsights={
            handleClickToggleDisplayAutomatedInsights
          }
          activeFilterTypes={notesFilteredType}
          onClickFilterType={handleClickNoteType}
          onClickShowAllFilterType={handleClickShowAllNoteFilterType}
          highlightedNotesAndClipsItem={highlightedNotesAndClipsItem}
        />
      </TabContentScroller>

      <TabContentScroller
        value={VideoTabName.Task}
        ref={taskScrollerRef}
        className={videoStyles.sidebarContent}
      >
        <Task
          scrollerRef={taskScrollerRef}
          video={video}
          currentVideoSecond={currentVideoSecond}
          taskStepsWithResponse={taskStepsWithResponse}
          onClickTime={onClickTime}
          isLegacyTask={isLegacyTask}
          isCurrentItemVisible={isCurrentTaskItemVisible}
          onChangeCurrentItemVisible={handleChangeCurrentTaskItemVisible}
          onClickDownloadVideoClip={handleClickDownloadVideoClip}
        />
      </TabContentScroller>

      <TabContentScroller
        value={VideoTabName.Transcript}
        ref={transcriptScrollerRef}
        className={videoStyles.sidebarContent}
      >
        {isTranscriptIncludedInPlan ? (
          <Transcript
            scrollerRef={transcriptScrollerRef}
            currentVideoSecond={currentVideoSecond}
            onClickTime={onClickTime}
            isCurrentItemVisible={isCurrentTranscriptItemVisible}
            onChangeCurrentItemVisible={
              handleChangeCurrentTranscriptItemVisible
            }
            videoId={video.id}
            videoDuration={video.duration}
            videoTranscript={video.transcript}
          />
        ) : (
          <TranscriptPromotion onClickUpgrade={handleClickUpgrade} />
        )}
      </TabContentScroller>

      <AddNoteOrClip
        videoId={video.id}
        refTextarea={refAddNoteNoteTextarea}
        isPlaying={isPlaying}
        currentVideoSecond={currentVideoSecond}
        videoDuration={video?.duration ?? 0}
        onSaveRequested={handleNoteSaveRequested}
        onClickTime={onClickTime}
        onClickTimeRange={onClickTimeRange}
        resetNotesFilterType={resetNotesFilterType}
        activeNotesFilterType={notesFilteredType}
        onClickPause={onClickPause}
        onClickPlay={onClickPlay}
      />
    </Tabs>
  );
}
