import { ReactNode } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import { Link } from "react-router-dom";

import { ButtonPrimary, Tooltip } from "@/design-system";
import { TestInsightsMetricsApi } from "@/entities/test/insights";
import { formatDateWithoutTime, toHMSText } from "@/helpers";

import { getStatIcon } from "./icons";

import styles from "./styles.module.css";

// TODO: Move to entities
export type Stat =
  | "taskCount"
  | "taskSuccessRate"
  | "avgTestDuration"
  | "dateOfTest";

// TODO: Move to utils if not dup. But this will be taken care of by entities anyway.
function isDate(value: any) {
  try {
    return !isNaN(Date.parse(value));
  } catch {
    return false;
  }
}

export function KeyMetrics({
  metrics,
  testHasReport,
  testId,
}: {
  metrics: TestInsightsMetricsApi;
  testHasReport: boolean;
  testId: number;
}) {
  const { task_count, success_rate, avg_duration, date_range } = metrics;

  return (
    <div className={styles.keyMetrics}>
      <StatCard
        isDisabled={typeof task_count !== "number"}
        type="taskCount"
        tooltip="The number of tasks in the test"
        label="Tasks "
      >
        {task_count}
      </StatCard>

      <StatCard
        isDisabled={typeof success_rate !== "number"}
        type="taskSuccessRate"
        tooltip="Percentage of tasks that were completed successfully"
        label="Task success rate "
      >
        {Math.round(success_rate * 100)}%
      </StatCard>

      <StatCard
        isDisabled={typeof avg_duration !== "number"}
        type="avgTestDuration"
        tooltip="Average time it took testers to complete the test"
        label="Avg. test duration "
      >
        {toHMSText(avg_duration)}
      </StatCard>

      <StatCard
        isDisabled={!isDate(date_range.from)}
        type="dateOfTest"
        tooltip="Date when testers were taking the test"
        label="Date of test "
      >
        {formatDateWithoutTime(new Date(date_range.from))}
        {date_range.to !== date_range.from && (
          <span> - {formatDateWithoutTime(new Date(date_range.to))}</span>
        )}
      </StatCard>

      {testHasReport && (
        <ButtonPrimary
          customTag={Link}
          to={`/test/${testId}/report`}
          className={styles.reportButton}
        >
          <FontAwesomeIcon icon={solid("arrow-turn-right")} /> Go to Report
        </ButtonPrimary>
      )}
    </div>
  );
}

function StatCard({
  children,
  type,
  tooltip,
  label,
  isDisabled,
}: {
  children: ReactNode;
  type: Stat;
  tooltip: string;
  label: string;
  isDisabled: boolean;
}) {
  const Icon = getStatIcon(type);
  return (
    <div className={styles.statCard}>
      <Icon
        className={cn(
          styles.statCardIcon,
          isDisabled && styles.statCardIcon_disabled,
        )}
      />
      <div>
        {!isDisabled && <div className={styles.statCardValue}>{children}</div>}
        <div
          className={cn(
            styles.statCardLabel,
            isDisabled && styles.statCardLabel_disabled,
          )}
        >
          {label} &nbsp;
          <Tooltip content={tooltip} />
        </div>
      </div>
    </div>
  );
}
