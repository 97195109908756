import { useEffect, useRef } from "react";
import { cn } from "@sys42/utils";

import { Checkbox, DropDownDialog } from "@/design-system";

import {
  CategoryLabelClearButtonProps,
  DropdownProps,
  OptionProps,
} from "./types";
import { selectOptionsWithKeyboardArrows } from "./utils";

import styles from "./dropdown.module.css";

/**
 * Dropdown
 */
export function Dropdown({
  activeCount,
  onOpen,
  onClose,
  icon,
  label,
  isOpen,
  optionIds,
  renderOption,
  activeOptions,
  onClickOption,
  isActive,
  onClickClear,
  footerOptionProps,
  isDisplayClearButton,
}: DropdownProps) {
  const optionsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = (e: KeyboardEvent) =>
        selectOptionsWithKeyboardArrows(e, optionsRef.current?.children);

      isOpen && document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isOpen]);

  const activeCountElement = (
    <span className={styles.count}>{activeCount}</span>
  );

  const labelElement = (
    <>
      {icon} {label} {activeCount ? activeCountElement : ""}
    </>
  );

  return (
    <DropDownDialog
      onOpen={onOpen}
      onClose={isOpen ? onClose : undefined}
      align={"left"}
      label={labelElement}
      open={isOpen}
      triggerButtonClassName={cn(
        styles.triggerButton,
        isActive && styles.triggerButton_active,
      )}
    >
      <div className={styles.content}>
        <CategoryLabelClearButton
          label={label}
          onClickClear={onClickClear}
          isDisplayClearButton={isDisplayClearButton}
        />
        <div className={styles.options} ref={optionsRef}>
          {optionIds.map((id, index) => (
            <Option
              key={id}
              id={id}
              onClick={() => onClickOption(id)}
              isActive={Boolean(activeOptions?.includes(id))}
            >
              {renderOption(index)}
            </Option>
          ))}
          {footerOptionProps && <Option {...footerOptionProps} />}
        </div>
      </div>
    </DropDownDialog>
  );
}

/**
 * Filter Category Label & Clear Button
 */
function CategoryLabelClearButton({
  label,
  isDisplayClearButton,
  onClickClear,
}: CategoryLabelClearButtonProps) {
  return (
    <div className={styles.categoryLabelClearButtonContainer}>
      <div className={styles.categoryLabel}>{label}</div>
      {isDisplayClearButton && (
        <button className={styles.clearSelectionButton} onClick={onClickClear}>
          Clear selection
        </button>
      )}
    </div>
  );
}

/**
 * Dropdown Option
 */
function Option({ children, isActive, onClick, id }: OptionProps) {
  return (
    <Checkbox
      id={`clip-filter-option-${id}`} // required for keyboard selection
      className={cn(styles.option, isActive && styles.option_active)}
      childrenContainerClassName={styles.optionChildrenContainer}
      onChange={onClick} // HACK
      checked={isActive}
    >
      {children}
    </Checkbox>
  );
}
