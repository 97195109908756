import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class Table extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, styles, className } = this.props;
    return (
      <table
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root)}
      >
        {children}
      </table>
    );
  }
}

export default Table;
