import React, { useContext } from "react";
import { cn } from "@sys42/utils";
import DonutChart from "react-svg-donut-chart";

import { TaskResultContext } from "..";
import { TaskResponseItem } from "../TaskResponseItem";
import { TaskResponseList } from "../TaskResponseList";
import { TaskResultsSummaryBox } from "../TaskResultsSummaryBox";
import { TaskTypeIcon } from "../TaskTypeIcon";

import styles from "./styles.module.css";

import iconNotSuccessful from "../not-successful.svg";
import { ReactComponent as SvgIconPassed } from "../passed.svg";
import iconSuccessful from "../successful.svg";

export function TaskResultsTask() {
  const {
    task,
    taskId,
    testId,
    testHasUnfinishedDeliveries,
    shownResponses,
    countResponses,
    countUpToDateResponses,
    stepNumber,
    averageTimeOnTask,
    showUpToDateOnly,
    onChangeShowUpToDateOnly,
    options,
  } = useContext(TaskResultContext);

  const askCompleted = options?.ask_completed;

  const countCompleted = shownResponses.reduce(
    (acc, cur) => (cur.response?.completed === true ? acc + 1 : acc),
    0,
  );
  const countNotCompleted = shownResponses.reduce(
    (acc, cur) => (cur.response?.completed === false ? acc + 1 : acc),
    0,
  );

  return (
    <div className={styles.taskResultsAskCompleted}>
      <TaskResultsSummaryBox
        icon={<TaskTypeIcon taskType={"task"} askCompleted={askCompleted} />}
        taskTypeName={"Task"}
        stepNumber={stepNumber}
        task={task}
        taskId={taskId}
        testId={testId}
        testHasUnfinishedDeliveries={testHasUnfinishedDeliveries}
        countShownResponses={shownResponses.length}
        countResponses={countResponses}
        countUpToDateResponses={countUpToDateResponses}
        showUpToDateOnly={showUpToDateOnly}
        onChangeShowUpToDateOnly={onChangeShowUpToDateOnly}
        averageTimeOnTask={averageTimeOnTask}
      >
        {askCompleted && countCompleted + countNotCompleted > 0 && (
          <div className={styles.chart}>
            <div className={styles.chartDonut}>
              <DonutChart
                width={"100%"}
                data={[
                  {
                    title: "Successful",
                    value: countCompleted,
                    stroke: "#15E0AF",
                    strokeWidth: 6,
                  },
                  {
                    title: "Not Successful",
                    value: countNotCompleted,
                    stroke: "#FF4018",
                    strokeWidth: 6,
                  },
                ]}
              />
            </div>
            <div className={styles.chartLegend}>
              <div
                className={cn(
                  styles.chartLegendItem,
                  styles.chartLegendItem_successful,
                )}
              >
                Successful ({countCompleted})
              </div>
              <div
                className={cn(
                  styles.chartLegendItem,
                  styles.chartLegendItem_notSuccessful,
                )}
              >
                Not successful ({countNotCompleted})
              </div>
            </div>
          </div>
        )}
      </TaskResultsSummaryBox>
      <div className={styles.responses}>
        <TaskResponseList>
          {shownResponses.map((response, index) => {
            let differingTestStepTooltipContent = null;
            if (response.differing_teststep) {
              differingTestStepTooltipContent = (
                <>
                  <div>{response.differing_teststep.task}</div>
                  {/*              <div className={styles.differingTeststepAskCompleted}>Ask testers if they completed the task
                successfully: {response.differing_teststep.options.ask_completed ? 'Yes' : 'No'}</div>*/}
                </>
              );
            }
            return (
              <TaskResponseItem
                key={index}
                video={response.video}
                timeOnTask={response.time_on_task}
                timestampCompleted={response.timestamp_completed}
                testId={testId}
                notes={response.notes}
                clips={response.clips}
                differingTestStepTooltipContent={
                  differingTestStepTooltipContent
                }
                className={cn(
                  !!response.differing_teststep &&
                    styles.taskResponseItem_outdated,
                )}
              >
                <div
                  className={cn(
                    styles.result,
                    response.response?.completed !== true &&
                      response.response?.completed !== false &&
                      styles.result_notAsked,
                  )}
                >
                  {response.response?.completed === true && (
                    <>
                      <img
                        className={styles.icon}
                        src={iconSuccessful}
                        alt="Successful"
                      />{" "}
                      Successful
                    </>
                  )}
                  {response.response?.completed === false && (
                    <>
                      <img
                        className={styles.icon}
                        src={iconNotSuccessful}
                        alt="Not successful"
                      />
                      Not successful
                    </>
                  )}
                  {askCompleted &&
                    response.response?.completed !== true &&
                    response.response?.completed !== false && <>Not asked</>}
                  {!askCompleted &&
                    response.response?.completed !== true &&
                    response.response?.completed !== false && (
                      <>
                        <SvgIconPassed
                          height={undefined}
                          width={undefined}
                          className={styles.icon}
                        />{" "}
                        Passed
                      </>
                    )}
                </div>
              </TaskResponseItem>
            );
          })}
        </TaskResponseList>
      </div>
    </div>
  );
}
