import { cn } from "@sys42/utils";

import { LogoFull } from "@/design-system";

import styles from "./styles.module.css";

/**

  Logo in a screen width container and positioned
  at the top of the screen.

*/
export function LogoBanner({
  endComponent,
}: {
  endComponent?: React.ReactNode;
}) {
  return (
    <div className={cn(styles.logoBanner)}>
      <a href="https://www.userbrain.com/en">
        <LogoFull />
      </a>
      {endComponent}
    </div>
  );
}
