import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import {
  ButtonLink,
  OverflowMenu,
  OverflowMenuItem,
  ToggleButton,
  Tooltip,
} from "@/design-system";
import { textCantEditTest, toHMSText } from "../../../helpers";

import styles from "./styles.module.css";

export function TaskResultsSummaryBox(props) {
  const {
    className,
    icon,
    stepNumber,
    task,
    taskId,
    testId,
    testHasUnfinishedDeliveries,
    taskTypeName,
    countResponses,
    countShownResponses,
    countUpToDateResponses,
    showUpToDateOnly,
    onChangeShowUpToDateOnly,
    averageTimeOnTask,
    averageRating,
    onClickEdit,
    children,
    ...containerProps
  } = props;

  const hasOutdatedResponses = countResponses - countUpToDateResponses > 0;

  let editTestMenuItem = (
    <OverflowMenuItem
      linkTo={`/test/${testId}/setup#task-${taskId}`}
      disabled={testHasUnfinishedDeliveries}
    >
      Edit Task
    </OverflowMenuItem>
  );

  if (testHasUnfinishedDeliveries) {
    editTestMenuItem = (
      <Tooltip small style={{ display: "block" }} content={textCantEditTest}>
        {editTestMenuItem}
      </Tooltip>
    );
  }

  function handleClickShowAll() {
    onChangeShowUpToDateOnly({ target: { checked: false } });
  }

  return (
    <div
      className={cn(className, styles.taskResultsSummaryBox)}
      {...containerProps}
    >
      <div className={styles.header}>
        <div className={styles.taskTypeLabel}>
          {icon}
          {stepNumber}.&nbsp;{taskTypeName}
        </div>
        <OverflowMenu>
          {hasOutdatedResponses && (
            <div
              className={styles.showTestersOfLatesVariationContainer}
              onMouseUp={(e) => {
                e.stopPropagation(); /* Prevent click event from closing the overflow menu */
              }}
            >
              <div>Only include testers who have seen the latest version</div>
              {/*<Tooltip content={''} />*/}
              <ToggleButton
                checked={showUpToDateOnly}
                onChange={onChangeShowUpToDateOnly}
              />
            </div>
          )}
          {editTestMenuItem}
        </OverflowMenu>
      </div>

      <div className={styles.taskContent}>
        <div style={{ whiteSpace: "pre-wrap" }}>{task}</div>
        <div>{children}</div>
        <div className={styles.responsesInfo}>
          {hasOutdatedResponses ? (
            <div>
              <Tooltip
                content={`${
                  countResponses - countUpToDateResponses
                } testers have seen an older version of this task/question`}
              >
                <div
                  className={cn(
                    styles.responsesInfoTesters,
                    styles.responsesInfoTesters_withOutdated,
                  )}
                >
                  {showUpToDateOnly ? (
                    <>
                      Showing {countShownResponses} of {countResponses} tester
                      {countResponses !== 1 && "s"}&nbsp;
                    </>
                  ) : (
                    <>
                      {countShownResponses} Tester
                      {countShownResponses !== 1 && "s"}&nbsp;
                    </>
                  )}
                  <FontAwesomeIcon icon={solid("clock-rotate-left")} />
                </div>
              </Tooltip>
              {showUpToDateOnly && (
                <>
                  &nbsp;
                  <ButtonLink
                    className={styles.responsesInfoShowAll}
                    onClick={handleClickShowAll}
                  >
                    Show all
                  </ButtonLink>
                </>
              )}
            </div>
          ) : (
            <div className={styles.responsesInfoTesters}>
              {countShownResponses} Tester{countShownResponses !== 1 && "s"}
            </div>
          )}
          {typeof averageRating === "number" && (
            <div>
              <abbr>Rating:</abbr>&nbsp;{averageRating}&nbsp;
              <Tooltip content={"Overall average rating from all testers"} />
            </div>
          )}
          {averageTimeOnTask != null && (
            <div>
              <abbr>Avg.</abbr>&nbsp;{toHMSText(averageTimeOnTask)}&nbsp;
              <Tooltip
                content={"Average time needed by testers for this step"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
