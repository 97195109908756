import React from "react";
import { cn } from "@sys42/utils";

// import { } from '../../system42'
import styles from "./styles.module.css";

export function AvatarGroup(props) {
  const { className, ...containerProps } = props;

  return (
    <div
      className={cn(className, styles.avatarGroup)}
      {...containerProps}
    ></div>
  );
}
