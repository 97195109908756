import React from "react";
import { cn } from "@sys42/utils";

import { Avatar, AvatarGroup } from "@/design-system";
import { useLocationState } from "@/hooks/useLocationState";
import { getTesterInitials, getTesterNameFromTester } from "../../../helpers";
import { TaskResultContext } from "..";

import styles from "./styles.module.css";

export function TaskResponseList(props) {
  const { className, children, ...containerProps } = props;
  const { taskId } = React.useContext(TaskResultContext);

  const childrenAsArray = React.Children.toArray(children);

  // This is saved in history state to preserve the state when the user navigates back
  const [isShowAllResponses, setIsShowAllResponses] = useLocationState(
    `task-response-list-expanded-${taskId}`,
    false,
  );

  const visibleChildren =
    childrenAsArray.length <= 6 || isShowAllResponses
      ? childrenAsArray
      : childrenAsArray.slice(0, 5);
  const hiddenChildren =
    childrenAsArray.length <= 6 || isShowAllResponses
      ? []
      : childrenAsArray.slice(5);

  function handleClickShowAllResponses() {
    setIsShowAllResponses(true);
  }

  return (
    <div className={cn(className, styles.taskResponseList)} {...containerProps}>
      {visibleChildren}
      {hiddenChildren.length > 0 && (
        <button
          className={styles.showMoreTesters}
          onClick={handleClickShowAllResponses}
        >
          <AvatarGroup className={styles.avatarGroup}>
            {hiddenChildren.slice(0, 3).map((child, index) => (
              <Avatar
                initials={getTesterInitials(
                  getTesterNameFromTester(child.props.video.tester),
                )}
                colorSeed={child.props.video.tester.id}
                key={index}
              />
            ))}
          </AvatarGroup>
          Show {hiddenChildren.length} more testers
        </button>
      )}
    </div>
  );
}
