import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps, noop } from "../../helpers";

import styles from "./styles.module.css";

class ChoiceChip extends React.Component {
  static defaultProps = { styles, onChange: noop };

  onClick = () => {
    this.props.onChange({ target: { value: this.props.value } });
  };

  render() {
    const { children, className, styles, selected } = this.props;
    return (
      <div
        className={cn(className, styles.root, selected && styles.selected)}
        {...extractContainerProps(this.props)}
        onClick={this.onClick}
      >
        {children}
      </div>
    );
  }
}

export default ChoiceChip;
