import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import toLower from "lodash/toLower";
import { ReactComponent } from "react-hotkeys";

import { ButtonPrimary } from "@/design-system";
import { Meter } from "@/design-system/components/Meter";
import { getWordingForTargetingValue, pluralizer } from "@/helpers";
import { TestInsightsTestersApi } from "../../../../entities/test/insights";

import {
  SvgIconGenderFemale,
  SvgIconGenderMale,
  SvgIconHead,
  SvgIconShowScreener,
} from "./icons";

import styles from "./styles.module.css";

export function TesterOverview({
  onClickGoToTesters,
  onClickShowScreeners,
  testers,
  screenerCount,
}: {
  onClickGoToTesters: () => void;
  onClickShowScreeners: () => void;
  testers: TestInsightsTestersApi;
  screenerCount: number;
}) {
  const maxValueDevices = Math.max(
    Object.values(testers.devices).reduce(
      (acc, curr) => Math.max(acc, curr),
      0,
    ),
  );

  const maxValueGenders = Math.max(
    Object.values(testers.genders).reduce(
      (acc, curr) => Math.max(acc, curr),
      0,
    ),
  );

  const maxValueCountries = Math.max(
    Object.values(testers.countries).reduce(
      (acc, curr) => Math.max(acc, curr),
      0,
    ),
  );

  const maxValueAgeRanges = Math.max(
    Object.values(testers.age_ranges).reduce(
      (acc, curr) => Math.max(acc, curr),
      0,
    ),
  );

  function getGenderIcon(
    gender: string,
  ): React.FunctionComponent<React.SVGProps<SVGSVGElement>> | undefined {
    if (gender === "F") {
      return SvgIconGenderFemale;
    }
    if (gender === "M") {
      return SvgIconGenderMale;
    } else {
      return undefined;
    }
  }

  function getDeviceTypeIcon(deviceType: string): ReactComponent {
    let fontAwesomeIcon: any;
    switch (deviceType) {
      case "mobile":
        fontAwesomeIcon = solid("mobile-alt");
        break;
      case "desktop":
        fontAwesomeIcon = solid("desktop");
        break;
      case "tablet":
        fontAwesomeIcon = solid("tablet-alt");
        break;
      default:
        return undefined;
    }
    return ({ className }: { className: string }) => (
      <FontAwesomeIcon
        className={className}
        icon={fontAwesomeIcon}
        fixedWidth
      />
    );
  }

  return (
    <div className={styles.testerOverview}>
      <div
        className={cn(
          styles.header,
          testers.count === 0 && styles.header_empty,
        )}
      >
        <SvgIconHead className={styles.headerIcon} />
        <div className={styles.headerContent}>
          <span className={styles.headerText}>
            {testers.count === 0
              ? "No testers yet"
              : pluralizer("Tester")(testers.count, true)}
          </span>
          {screenerCount > 0 && (
            <button
              onClick={onClickShowScreeners}
              className={styles.showScreenersButton}
            >
              <SvgIconShowScreener
                style={{ position: "relative", top: "-2px" }}
              />{" "}
              Show {pluralizer("screener")(screenerCount)}
            </button>
          )}
        </div>
      </div>

      {testers.count > 0 && (
        <div className={styles.statsSection}>
          {maxValueDevices > 0 && (
            <div className={styles.statGroup}>
              <h4 className={styles.statTitle}>Device Type</h4>
              {(
                Object.keys(testers.devices) as Array<
                  keyof typeof testers.devices
                >
              ).map(
                (deviceType) =>
                  testers.devices[deviceType] > 0 && (
                    <StatItem
                      key={deviceType}
                      max={maxValueDevices}
                      value={testers.devices[deviceType]}
                      title={getWordingForTargetingValue(
                        "device-alt",
                        deviceType,
                      )}
                      Icon={getDeviceTypeIcon(deviceType)}
                    />
                  ),
              )}
            </div>
          )}

          {maxValueGenders > 0 && (
            <div className={styles.statGroup}>
              <h4 className={styles.statTitle}>Gender</h4>
              {(
                Object.keys(testers.genders) as Array<
                  keyof typeof testers.genders
                >
              ).map(
                (gender) =>
                  testers.genders[gender] > 0 && (
                    <StatItem
                      key={gender}
                      max={maxValueGenders}
                      value={testers.genders[gender]}
                      title={getWordingForTargetingValue("gender", gender)}
                      Icon={getGenderIcon(gender)}
                    />
                  ),
              )}
            </div>
          )}

          {maxValueCountries > 0 && (
            <div className={styles.statGroup}>
              <h4 className={styles.statTitle}>Country</h4>
              {Object.keys(testers.countries)
                .sort((a, b) => a.localeCompare(b))
                .sort((a, b) => testers.countries[b] - testers.countries[a])
                .map((countryCode) => (
                  <StatItem
                    key={countryCode}
                    max={maxValueCountries}
                    value={testers.countries[countryCode]}
                    title={countryCode}
                    Icon={({ className }: { className: string }) => (
                      <img
                        className={cn(className, styles.flag)}
                        src={`/flags/1x1/${toLower(countryCode)}.svg`}
                        alt={countryCode}
                      />
                    )}
                  />
                ))}
            </div>
          )}

          {maxValueAgeRanges > 0 && (
            <div className={styles.statGroup}>
              <h4 className={styles.statTitle}>Age Range</h4>
              {Object.keys(testers.age_ranges).map(
                (ageRange) =>
                  testers.age_ranges[ageRange] > 0 && (
                    <StatItem
                      key={ageRange}
                      max={maxValueAgeRanges}
                      value={testers.age_ranges[ageRange]}
                      title={getWordingForTargetingValue("age", ageRange)}
                    />
                  ),
              )}
            </div>
          )}
        </div>
      )}

      {testers.count !== 0 && (
        <div className={styles.footer}>
          <ButtonPrimary
            onClick={onClickGoToTesters}
            className={styles.goToTestersButton}
          >
            <FontAwesomeIcon icon={solid("arrow-turn-right")} /> Go to Testers
          </ButtonPrimary>
        </div>
      )}
    </div>
  );
}

function StatItem({
  max,
  value,
  title,
  Icon,
}: {
  max: number;
  value: number;
  title: string;
  Icon?:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | ReactComponent;
}) {
  return (
    <div className={styles.statItem}>
      <label className={styles.statItemLabel}>
        {Icon && <Icon className={styles.statItemIcon} />}
        {title}
      </label>
      <Meter
        className={styles.statItemMeter}
        max={max}
        value={value}
        title={title}
      />
      <span className={styles.statItemValue}>{value}</span>
    </div>
  );
}
