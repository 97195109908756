import React from "react";
import { cn } from "@sys42/utils";
import PropTypes from "prop-types";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class InputText extends React.Component {
  static defaultProps = { styles, onChange: null, id: undefined };

  render() {
    const {
      onClick,
      autoComplete,
      innerRef,
      autoFocus,
      className,
      error,
      styles,
      value,
      onChange,
      placeholder,
      id,
      fullWidth,
      type,
      readOnly,
      onFocus,
      onBlur,
      isDisabled,
    } = this.props;

    const classNameInput = cn(
      className,
      styles.root,
      fullWidth && styles.fullWidth,
      error && styles.error,
      readOnly && styles.readOnly,
    );

    return (
      <input
        {...extractContainerProps(this.props)}
        className={classNameInput}
        onChange={onChange}
        readOnly={readOnly}
        ref={innerRef}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        type={type ? type : "text"}
        value={value}
        placeholder={placeholder}
        id={id}
        // XXX isDisabled is not ideal here because Button uses disabled
        disabled={isDisabled}
      />
    );
  }
}

InputText.propTypes = {
  onChange: PropTypes.func,
};

export default InputText;
