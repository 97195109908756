import { ReactNode, useContext, useEffect, useRef } from "react";
import { cn } from "@sys42/utils";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { CSSTransition, TransitionGroup } from "react-transition-group"; // Todo: make part of Design System

import Spinner from "../../Spinner";
import { ModalContext } from "../context";
import { hasActiveSubmodal } from "../helpers";
import { ModalContainer } from "../ModalContainer";

import styles from "./styles.module.css";

interface Modal2ModalProps {
  maxWidth?: string;
  onClose?: (reason: "clickOutside" | "esc") => void;
  // NOTE using ReactNode instead of JSX.Element to allow for null
  content: ReactNode;
  loading?: boolean;
  centered?: boolean;
  overflowHidden?: boolean;
}
interface Modal2Props extends Modal2ModalProps {
  isActive: boolean;
}

export function Modal2({ isActive, ...restProps }: Modal2Props) {
  return (
    <ModalContainer
      isActive={isActive}
      element={
        <TransitionGroup>
          {isActive && (
            <CSSTransition timeout={200} classNames={{ ...styles }}>
              <Modal {...restProps} />
            </CSSTransition>
          )}
        </TransitionGroup>
      }
    />
  );
}

function Modal({
  content,
  onClose,
  loading,
  centered,
  overflowHidden,
  maxWidth,
}: Modal2ModalProps) {
  const refContent = useRef<HTMLDivElement | null>(null);

  // At this time the modal context will contain the ref to the
  // modals own portal which contains all sub modals
  const { refSubmodals } = useContext(ModalContext);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const clickIsInsideContent = refContent.current?.contains(
        event.target as Node,
      );
      if (!hasActiveSubmodal(refSubmodals.current) && !clickIsInsideContent) {
        onClose?.("clickOutside");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, refSubmodals]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!hasActiveSubmodal(refSubmodals.current) && event.key === "Escape") {
        onClose?.("esc");
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, refSubmodals]);

  const contentStyle: React.CSSProperties = {};

  if (typeof maxWidth !== "undefined") {
    contentStyle.maxWidth = maxWidth;
    contentStyle.width = "100%";
  }

  if (overflowHidden) {
    contentStyle.overflow = "hidden";
  }

  return (
    <div className={styles.root}>
      <RemoveScrollBar />
      <div
        className={cn(styles.content, centered && styles.content_centered)}
        style={contentStyle}
        ref={refContent}
      >
        {loading ? <Spinner /> : content}
      </div>
    </div>
  );
}
