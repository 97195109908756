import { ReactNode, useCallback, useContext } from "react";
import { cn } from "@sys42/utils";

import { VideoPlayer } from "@/containers/Video/components/VideoPlayer";

import { VideoPlayerContext } from "./videoPlayerContext";

import styles from "./styles.module.css";

export function VideoPlayerWithSidebar({
  className,
  headerContent,
  sidebarContent,
  fullWidthHeader,
}: {
  className?: string;
  headerContent: ReactNode;
  sidebarContent: ReactNode;
  fullWidthHeader?: boolean;
}) {
  return (
    <div
      className={cn(
        styles.videoPlayerWithSidebar,
        !fullWidthHeader && styles.videoPlayerWithSidebar_headerSpansVideoOnly,
        className,
      )}
    >
      <div className={styles.header}>{headerContent}</div>
      <Player />
      <div className={styles.sidebar}>{sidebarContent}</div>
    </div>
  );
}

function Player() {
  const videoPlayerContext = useContext(VideoPlayerContext);
  const noop = useCallback(() => {}, []);

  if (!videoPlayerContext) {
    return <div className={styles.videoPlayerLoading}>Loading</div>;
  }

  return (
    <VideoPlayer
      {...videoPlayerContext}
      className={styles.videoPlayer}
      onSecondChange={videoPlayerContext.onSecondChange ?? noop}
      onPlay={videoPlayerContext.onPlay ?? noop}
      onPause={videoPlayerContext.onPause ?? noop}
      onEnded={videoPlayerContext.onEnded ?? noop}
      onStarted={videoPlayerContext.onStarted ?? noop}
    />
  );
}
