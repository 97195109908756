import React from "react";
import { cn } from "@sys42/utils";

import { Tooltip } from "@/design-system";
import { Meter } from "@/design-system/components/Meter";

import styles from "./styles.module.css";

export function UsageBoxItem(props) {
  const {
    className,
    children,
    title,
    amountAvailable,
    amountUsed,
    meterTitle,
    subject,
    tooltipContent,
    ...restProps
  } = props;

  return (
    <div className={cn(className, styles.usageBoxItem)} {...restProps}>
      <h2>{title}</h2>
      {amountAvailable != null && amountUsed != null && amountAvailable > 0 ? (
        <Meter
          className={styles.meter}
          max={amountAvailable}
          value={amountUsed}
          title={meterTitle}
        />
      ) : (
        <hr className={styles.hr} />
      )}
      <div className={styles.info}>
        {amountAvailable != null && amountUsed != null && (
          <>
            <span className={styles.number}>{amountUsed}</span> of{" "}
            {amountAvailable}
            {subject && ` ${subject}${amountAvailable !== 1 ? "s" : ""}`}
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              used {tooltipContent && <Tooltip content={tooltipContent} />}
            </span>
          </>
        )}
        {amountAvailable != null && amountUsed == null && (
          <>
            <span className={styles.number}>{amountAvailable}</span>
            {subject && ` ${subject}${amountAvailable !== 1 ? "s" : ""}`}
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              available {tooltipContent && <Tooltip content={tooltipContent} />}
            </span>
          </>
        )}
      </div>
    </div>
  );
}
