import React from "react";
import { cn } from "@sys42/utils";

import { OverflowMenu } from "@/design-system";

import styles from "./styles.module.css";

import imageLoadingGif from "../VideoListItem/Spin-1s-38px-new.gif";

export function PendingDeliveryListItem(props) {
  const {
    className,
    overflowMenuItems,
    targetingSummary,
    text,
    ...containerProps
  } = props;

  return (
    <div
      className={cn(className, styles.pendingDeliveryListItem)}
      {...containerProps}
    >
      <div className={styles.statusInfo}>
        <div className={styles.statusIndicator}>
          <img src={imageLoadingGif} alt="running" height={19} width={19} />
        </div>

        <div className={styles.content}>
          <div className={styles.text}>{text}</div>
          {targetingSummary && (
            <div className={styles.targetingSummary} title={targetingSummary}>
              {targetingSummary}
            </div>
          )}
        </div>

        {overflowMenuItems && (
          <OverflowMenu className={styles.overflowMenu}>
            {overflowMenuItems}
          </OverflowMenu>
        )}
      </div>
    </div>
  );
}
