import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";
import Label from "../Label";

import styles from "./styles.module.css";

class LabeledContent extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, className, styles, text } = this.props;
    const childrenAsArray = React.Children.toArray(children);
    let idOfFirstChild = undefined;
    if (childrenAsArray.length > 0 && childrenAsArray[0].props) {
      idOfFirstChild = childrenAsArray[0].props.id;
    }

    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root)}
      >
        <Label className={styles.label} htmlFor={idOfFirstChild}>
          {text}
        </Label>
        {children}
      </div>
    );
  }
}

export default LabeledContent;
