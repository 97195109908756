import styles from "./styles.module.css";

import icoLushSvg from "./images/ico-lush.svg";
import icoRedbullSvg from "./images/ico-redbullmobile.svg";
import icoSolarcitySvg from "./images/ico-solarcity.svg";
import icoSpotifySvg from "./images/ico-spotify.svg";
import icoVirginSvg from "./images/ico-virgin.svg";

const Brands = () => (
  <>
    <div className={styles.brandBox}>
      <div className={styles.brandList}>
        <div className={styles.brandItem}>
          <img src={icoSpotifySvg} alt="spotify" />
        </div>
        <div className={`${styles.brandItem} ${styles.virgin}"]}`}>
          <img src={icoVirginSvg} alt="virgin" />
        </div>
        <div className={styles.brandItem}>
          <img src={icoRedbullSvg} alt="redbullmobile" />
        </div>
        <div className={styles.brandItem}>
          <img src={icoSolarcitySvg} alt="solarcity" />
        </div>
        <div className={`${styles.brandItem} ${styles.brandItem_desktop}`}>
          <img src={icoLushSvg} alt="lush fresh handmade cosmetics" />
        </div>
      </div>
      <div className={styles.brandTitle}>
        Trusted by UX designers &amp; product teams around the world.
      </div>
    </div>
  </>
);

export default Brands;
