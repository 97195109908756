import React from "react";
import { cn } from "@sys42/utils";

import { ButtonPrimary, Tooltip } from "@/design-system";
import {
  BILLING_CYCLE_ANNUAL,
  formatDateWithoutTime,
  formatPrice,
  formatPriceWithBillingCycle,
  getIncludedTestersForPlan,
  getPlanName,
  pluralizer,
} from "../../helpers";

import styles from "./styles.module.css";

export function SubscriptionBox(props) {
  const {
    className,
    title,
    onClickEditSubscription,
    onClickReactivateSubscription,
    /** @type UserbrainSubscription */
    subscription,
    /** @type UserbrainFreeTrial */
    freeTrial,
    reactivateFetching,
    isOwnerOrAdmin,
    ...containerProps
  } = props;

  const { plan, contactSupportToEdit } = subscription;

  const planName = getPlanName(plan, subscription.quantity);
  const planPrice = subscription.plan_price / 100;
  const nextChargeDate = subscription.current_period_end;
  const isCancelled = subscription.cancel_at_period_end;
  const isOutdated = !subscription.plan_up_to_date;
  const isLegacy = plan === "legacy";

  let planInfo = null;
  const includedCredits = getIncludedTestersForPlan(plan);
  if (includedCredits > 0) {
    if (subscription.billing_cycle === BILLING_CYCLE_ANNUAL) {
      planInfo = <>{includedCredits * 12}&nbsp;testers included each year</>;
    } else {
      planInfo = <>{includedCredits}&nbsp;testers included each month</>;
    }
  }

  const showContactSupportOnly = contactSupportToEdit;

  // Todo: legacy is not the same as outdated: Legacy are the old plans with custom amount, outdated is every plan that
  // is not available anymore in the future this could also be an old pro plan
  const showReactivateSubscription =
    !showContactSupportOnly && isCancelled && !isOutdated;
  const showEditSubscription =
    !showContactSupportOnly && (!isCancelled || (isCancelled && isOutdated));

  function handleClickEditSubscription() {
    onClickEditSubscription();
  }

  function handleClickReactivateSubscription() {
    onClickReactivateSubscription();
  }

  return (
    <section
      className={cn(className, styles.subscriptionBox, "card")}
      {...containerProps}
    >
      <div className={styles.header}>
        <div className={styles.titleAndStatus}>
          <h1 className={styles.title}>{title}</h1>
          {isCancelled ? (
            <div className={styles.status}>
              Cancelled{" "}
              <Tooltip
                content={`Your subscription was cancelled and is still active until ${formatDateWithoutTime(
                  nextChargeDate,
                )}. Your payment card won’t be charged again.`}
              />
            </div>
          ) : (
            <div className={styles.status}>
              Active
              {showContactSupportOnly && (
                <>
                  &nbsp;
                  <Tooltip
                    content={`Contact support to change or cancel your subscription.`}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {isOwnerOrAdmin && (
          <div className={styles.actions}>
            {showContactSupportOnly && (
              <div className={styles.contactSupportWrapper}>
                <ButtonPrimary
                  href={"mailto:support@userbrain.com"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact support
                </ButtonPrimary>
              </div>
            )}

            {showEditSubscription && (
              <ButtonPrimary onClick={handleClickEditSubscription}>
                Edit subscription
              </ButtonPrimary>
            )}

            {showReactivateSubscription && (
              <ButtonPrimary
                onClick={handleClickReactivateSubscription}
                disabled={reactivateFetching}
              >
                {reactivateFetching
                  ? "Reactivating…"
                  : "Reactivate subscription"}
              </ButtonPrimary>
            )}
          </div>
        )}
      </div>
      <div className={styles.plan}>
        <div>
          <span className={styles.planName}>
            {planName}
            {!isLegacy && " plan"}
          </span>
          {isOutdated && (
            <span className={styles.planOutdated}> (outdated&nbsp;plan)</span>
          )}
          {planInfo && (
            <>
              {" "}
              <span className={styles.planInfo}>{planInfo}</span>
            </>
          )}
        </div>
        <span className={styles.planPrice}>
          {formatPriceWithBillingCycle(
            planPrice,
            subscription.plan_currency,
            subscription.billing_cycle,
          )}
          {!!subscription.tax_percent && (
            <>
              {" "}
              (incl. {subscription.tax_percent}%{" "}
              <abbr title="Value Added Tax">VAT</abbr>){" "}
            </>
          )}
        </span>
      </div>

      {freeTrial?.isActive && (
        <div className={styles.plan}>
          <div>
            <span className={styles.planName}>Free Trial</span>
            <span className={styles.planInfo}>
              {pluralizer("day")(freeTrial.daysLeft, true)} left
            </span>
          </div>
          <span className={styles.planPrice}>Free</span>
        </div>
      )}

      <div className={styles.totals}>
        <div>
          Total{" "}
          {!isCancelled && (
            <span className={styles.nextChargeDate}>
              (next charge on {formatDateWithoutTime(nextChargeDate)})
            </span>
          )}
        </div>
        <span className={styles.totalsPrice}>
          {formatPrice(planPrice, subscription.plan_currency)}
        </span>
      </div>
    </section>
  );
}
