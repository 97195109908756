import React from "react";
import { cn } from "@sys42/utils";

import FeatureListItem from "./FeatureListItem";

import styles from "./styles.module.css";

export default function FeatureList({
  children,
  className,
  ...containerProps
}) {
  return (
    <ul className={cn(className, styles.featureList)} {...containerProps}>
      {children}
    </ul>
  );
}

export { FeatureListItem };
