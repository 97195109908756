import React from "react";
import { cn } from "@sys42/utils";
import trim from "lodash/trim";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class ExternalLink extends React.Component {
  static defaultProps = { styles };

  render() {
    const { styles } = this.props;
    let { className, children, href } = this.props;

    href = trim(href, "/");

    if (React.Children.count(children) < 1) {
      children = href;
    }

    return (
      <a
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root)}
        href={href}
        rel="noopener noreferrer"
        target={"_blank"}
      >
        {children}
      </a>
    );
  }
}

export default ExternalLink;
