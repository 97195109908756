import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

export default function Checkbox(props) {
  const {
    id,
    checked,
    children,
    className,
    onChange,
    value,
    disabled,
    childrenContainerClassName,
    isError,
  } = props;

  return (
    <label
      {...extractContainerProps(props)}
      htmlFor={id}
      tabIndex={-1}
      className={cn(
        className,
        styles.root,
        isError && styles.root_error,
        styles.label,
      )}
    >
      <input
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        value={value}
        checked={checked}
        id={id}
        className={styles.checkbox}
      />
      <div className={styles.inputCheck} />
      <div className={childrenContainerClassName}>{children}</div>
    </label>
  );
}
