import { useEffect } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import { useDispatch } from "react-redux";

import { Video } from "@/containers/Video/types";
import { ErrorMessage, Stack } from "@/design-system";
import { usePrevious } from "@/helpers";
import { useAppSelector } from "@/store";

import styles from "../../styles.module.css";

/**


  Generate Automated Insights

*/
export interface GenerateAutomatedInsightsProps {
  videoId: number;
  videoAiStatus: Video["ai_status"];
}

/**


  Generate Automated Insights

*/
export function GenerateAutomatedInsights({
  videoId,
  videoAiStatus,
}: GenerateAutomatedInsightsProps) {
  const dispatch = useDispatch();
  const prevVideoAiStatus = usePrevious(videoAiStatus);

  const aiFetching = useAppSelector((state) => state.video.aiFetching);
  const aiSuccess = useAppSelector((state) => state.video.aiSuccess);
  const prevAiFetching = usePrevious(aiFetching);
  const aiErrorMessage = useAppSelector(
    (state) => state.video.aiError?.message,
  );
  const aiVideoId = useAppSelector((state) => state.video.aiVideoId);
  const aiVideoIdIsForThisVideo = aiVideoId === videoId;

  function handleClickGenerateAutomatedInsights() {
    dispatch({ type: "VIDEO_AI_REQUEST", videoId: videoId });
  }

  useEffect(() => {
    if (videoAiStatus === "processing" && prevVideoAiStatus == null) {
      // Request ai if the video is opened and the status is processing (null to make sure its not changing from user request)
      dispatch({ type: "VIDEO_AI_REQUEST", videoId });
    }
  }, [videoAiStatus, prevVideoAiStatus, dispatch, videoId]);

  useEffect(() => {
    if (
      videoAiStatus === "processing" &&
      aiFetching === false &&
      prevAiFetching === true &&
      aiSuccess === true
    ) {
      // Try again if the previous request was successful but the status is still processing
      setTimeout(() => {
        dispatch({ type: "VIDEO_AI_REQUEST", videoId });
      }, 3000);
    }
  }, [videoAiStatus, videoId, aiFetching, prevAiFetching, dispatch, aiSuccess]);

  return (
    <div className={styles.generateAutomatedInsights}>
      <Stack spacing={"small"}>
        <div>
          Efficiently analyze videos with AI-generated insights. Less manual
          note-taking, more focus on watching your videos.
        </div>
        {aiErrorMessage &&
        aiErrorMessage !== "Request aborted" &&
        aiVideoIdIsForThisVideo ? (
          <ErrorMessage>{aiErrorMessage}</ErrorMessage>
        ) : (
          <button
            disabled={
              videoAiStatus === "processing" ||
              (aiFetching && aiVideoIdIsForThisVideo)
            }
            onClick={handleClickGenerateAutomatedInsights}
            className={cn(
              styles.generateAutomatedInsightsButton,
              (videoAiStatus === "processing" ||
                (aiFetching && aiVideoIdIsForThisVideo)) &&
                styles.generateAutomatedInsightsButton_pending,
            )}
          >
            {videoAiStatus === "processing" ||
            (aiFetching && aiVideoIdIsForThisVideo) ? (
              <>
                <FontAwesomeIcon icon={solid("spinner")} fixedWidth spin />{" "}
                Generating insights…
              </>
            ) : (
              "Generate AI insights"
            )}
          </button>
        )}
      </Stack>
    </div>
  );
}
