import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { Tooltip } from "@/design-system";

import styles from "./styles.module.css";

export function DifferingTestStepInfo(props) {
  const { className, children, ...containerProps } = props;

  return (
    <div
      className={cn(className, styles.differingTestStepInfo)}
      {...containerProps}
    >
      <Tooltip
        width={"19rem"}
        content={
          <>
            <div className={styles.topInfo}>
              This tester has seen an older version:
            </div>
            {children}
          </>
        }
      >
        <FontAwesomeIcon
          icon={solid("clock-rotate-left")}
          className={styles.icon}
        />
      </Tooltip>
    </div>
  );
}
