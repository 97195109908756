import { CSSProperties } from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

// TODO: In the future this component could include an Avatar, Card, Heading, List etc.

/**
 * A loading state primitive which is a simple rectangle with a
 * background animation applied. It has no size by default.
 */
export function Skeleton({
  style,
  className,
}: {
  style?: CSSProperties;
  className?: string;
}) {
  return <div style={style} className={cn(styles.skeleton, className)} />;
}

type SentenceProps = {
  isWidthRandom?: boolean;
  widthMin?: number;
};

/**
 * A component that renders a skeleton sentence.
 * It can be used to create a loading skeleton effect for text content.
 */
export function SkeletonSentence({
  isWidthRandom = true,
  widthMin = 40,
}: SentenceProps) {
  const width = isWidthRandom
    ? `${Math.random() * (100 - widthMin) + widthMin}%`
    : undefined;

  return (
    <Skeleton
      style={{
        width,
      }}
    />
  );
}

type ParagraphProps = {
  lineCount?: number;
  lineCountMin?: number;
  lineCountMax?: number;
  className?: string;
};

/**
 * A component that renders a skeleton paragraph.
 * It can be used to create a loading skeleton effect for paragraphs of text content.
 *
 * @param lineCount The exact number of sentences to render which will take precedence if defined.
 * @param lineCountMin The minimum number of sentences to render.
 * @param lineCountMax The maximum number of sentences to render.
 * @param sentenceProps The props to pass to each `SkeletonSentence` component.
 */
export function SkeletonParagraph({
  lineCount,
  lineCountMin = 1,
  lineCountMax = 3,
  className,
}: ParagraphProps) {
  const randomCount =
    Math.floor(Math.random() * (lineCountMax - lineCountMin + 1)) +
    lineCountMin;
  const sentences = Array.from(
    {
      length: lineCount ?? randomCount,
    },
    (_, i) => (
      <SkeletonSentence key={i} isWidthRandom={randomCount === i + 1} />
    ),
  );

  return <div className={className}>{sentences}</div>;
}
