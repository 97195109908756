import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import { Link } from "react-router-dom";

import { noop } from "@/helpers";
import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class OverflowMenuItem extends React.Component {
  static defaultProps = { styles };

  render() {
    const {
      children,
      className,
      styles,
      caution,
      icon,
      linkTo,
      historyState,
      href,
      download,
      disabled,
    } = this.props;

    const linkContent = (
      <>
        {icon && (
          <span className={styles.icon}>
            <FontAwesomeIcon fixedWidth icon={icon} />
          </span>
        )}
        {children}
      </>
    );

    if (href && !disabled) {
      return (
        <a
          {...extractContainerProps(this.props)}
          href={href}
          download={download}
          className={cn(
            className,
            styles.root,
            styles.rootLink,
            caution && styles.caution,
          )}
        >
          {linkContent}
        </a>
      );
    } else if (linkTo && !disabled) {
      return (
        <Link
          {...extractContainerProps(this.props)}
          to={linkTo}
          state={historyState}
          className={cn(
            className,
            styles.root,
            styles.rootLink,
            caution && styles.caution,
          )}
        >
          {linkContent}
        </Link>
      );
    } else {
      const propsForDiv = extractContainerProps(this.props);
      if (disabled) {
        // Prevents overflow menu from disappearing when clicking it
        propsForDiv.onMouseUp = (e) => {
          e.stopPropagation();
        };
        propsForDiv.onClick = noop;
      }
      return (
        <div
          {...propsForDiv}
          className={cn(
            className,
            styles.root,
            caution && styles.caution,
            disabled && styles.disabled,
          )}
        >
          {linkContent}
        </div>
      );
    }
  }
}

export default OverflowMenuItem;
