import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class TogglePlayButton extends React.Component {
  static defaultProps = { styles };

  render() {
    const {
      checked,
      defaultChecked,
      disabled,
      children,
      className,
      onChange,
      styles,
      value,
    } = this.props;

    return (
      <label
        {...extractContainerProps(this.props)}
        className={cn(className, styles.switch, disabled && styles.disabled)}
      >
        <input
          type="checkbox"
          onChange={onChange}
          value={value}
          checked={checked}
          disabled={disabled}
          defaultChecked={defaultChecked}
        />
        <span className={`${styles.slider} ${styles.round}`}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={solid("play")} fixedWidth />
          </div>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={solid("pause")} fixedWidth />
          </div>
        </span>
        {children && <span className={styles.label}>{children}</span>}
      </label>
    );
  }
}

export default TogglePlayButton;
