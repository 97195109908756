import { cn } from "@sys42/utils";
import capitalize from "lodash/capitalize";

import { SvgIconModifyClip, SvgIconModifyNote } from "../../../icons";
import { NoteType } from "../../types";

import styles from "./styles.module.css";

type onClickTab = (sectionName: NoteType) => void;

type TabsProps = {
  value: NoteType;
  onClick: onClickTab;
};

/**
 *
 * Tabs - switch between create note and clip.
 */
export function Tabs({ value, onClick }: TabsProps) {
  return (
    <div className={styles.tabs}>
      <Tab
        sectionName="note"
        onClick={onClick}
        isActive={value === "note"}
        activeIconColor="#777DFB"
        SvgIcon={SvgIconModifyNote}
      />
      <Tab
        sectionName="clip"
        onClick={onClick}
        isActive={value === "clip"}
        activeIconColor="var(--color-primary-1)"
        SvgIcon={SvgIconModifyClip}
      />
    </div>
  );
}

/**
 *
 * Tab - Clip or Note
 */
function Tab({
  isActive,
  onClick,
  sectionName,
  SvgIcon,
  activeIconColor,
}: {
  isActive: boolean;
  onClick: onClickTab;
  sectionName: NoteType;
  SvgIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeIconColor: string;
}) {
  return (
    <button
      onClick={() => onClick(sectionName)}
      style={{
        ["--active-icon-color" as string]: activeIconColor,
      }}
      className={cn(styles.tab, isActive && styles.tab_active)}
    >
      <SvgIcon className={cn(styles.tabIcon)} />
      {capitalize(sectionName)}
    </button>
  );
}
