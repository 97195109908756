import React from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export default function Index(props) {
  const {
    scaleStartLabel,
    scaleEndLabel,
    scaleStart,
    scaleEnd,
    onChange,
    value,
  } = props;

  function handleClickButton(item) {
    onChange(item);
  }

  const scaleItems = [];

  for (let i = scaleStart; i <= scaleEnd; i++) {
    scaleItems.push(i);
  }

  return (
    <div>
      <div className={styles.labels}>
        <div className={styles.label}>{scaleStartLabel}</div>
        <div className={styles.label}>{scaleEndLabel}</div>
      </div>
      <div className={styles.items}>
        {scaleItems.map((item, index) => (
          <button
            key={index}
            className={cn(styles.item, value === item && styles.item_active)}
            onClick={() => handleClickButton(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
}
