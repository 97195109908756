import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export default function FeatureList({
  children,
  className,
  ...containerProps
}: {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLLIElement>) {
  return (
    <li className={cn(className, styles.featureListItem)} {...containerProps}>
      {children}
    </li>
  );
}
