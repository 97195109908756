import React from "react";

import { ButtonPrimary } from "@/design-system";
import { Modal2 } from "../Modal";

import styles from "./styles.module.css";

const urlKnowledgeBase =
  "https://help.userbrain.com/help/why-haven-t-i-received-all-the-free-testers-in-my-trial";

export default function ModalWelcomeFreeTrial(props) {
  const { onClose, isActive, receivedSessions, onClickStartExploring } = props;

  return (
    <Modal2
      onClose={onClose}
      isActive={isActive}
      content={
        <Content
          onClose={onClose}
          receivedSessions={receivedSessions}
          onClickStartExploring={onClickStartExploring}
        />
      }
    />
  );
}

function Content(props) {
  const { receivedSessions, onClickStartExploring, onClose } = props;

  return (
    <div className={styles.content}>
      <button className={styles.closeButton} onClick={onClose} type={"button"}>
        &times;
      </button>
      <h1 className={styles.title}>Welcome to Userbrain!</h1>

      {receivedSessions && (
        <p className={styles.text1}>
          Your <span style={{ whiteSpace: "nowrap" }}>14-day</span> free trial
          includes 2&nbsp;Userbrain&nbsp;testers, 5 of your own testers, and
          access to all features.
        </p>
      )}

      {!receivedSessions && (
        <>
          <p className={styles.text1}>
            Your <span style={{ whiteSpace: "nowrap" }}>14-day</span> free trial
            includes unlimited team members, and access to all features.
          </p>
          <a href={urlKnowledgeBase} target="_blank" rel="noreferrer">
            Why haven't I received my free testers?
          </a>
        </>
      )}

      <ButtonPrimary className={styles.button} onClick={onClickStartExploring}>
        Start exploring now
      </ButtonPrimary>
    </div>
  );
}
