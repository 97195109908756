import { useCallback, useRef, useState } from "react";
import { cn } from "@sys42/utils";

import TaskTimeline from "@/components/TaskTimeline";

import {
  TabContent,
  TabContentScroller,
  TabList,
  Tabs,
} from "./components/VideoTabs";
import {
  DetailsTabProps,
  SharedVideoTabName,
  TaskTabProps,
  TranscriptTabProps,
} from "./sharedTypes";
import Transcript from "./Sidebar/Transcript";

import videoStyles from "../Video/styles.module.css";

/**


  Shared Video Sidebar

  TODO

  [] Fix scroller pushing tabsList over.

*/

type SidebarProps = DetailsTabProps & TaskTabProps & TranscriptTabProps;

export function SidebarShared({
  videoDuration,
  onClickTime,
  videoTitle,
  videoTestUrl,
  taskStepsWithResponse,
  currentVideoTime,
  isLegacyTask,
  videoId,
  videoTranscript,
  currentVideoSecond,
}: SidebarProps) {
  const [activeTabState, setActiveTabState] = useState(SharedVideoTabName.Task);

  function handleChangeTab(newTab: SharedVideoTabName) {
    setActiveTabState(newTab);
  }

  return (
    <Tabs
      value={activeTabState}
      onChange={handleChangeTab}
      className={videoStyles.tabs}
    >
      <TabList
        tabs={[
          { value: SharedVideoTabName.Details, label: "Details" },
          { value: SharedVideoTabName.Task, label: "Task" },
          { value: SharedVideoTabName.Transcript, label: "Transcript" },
        ]}
      />
      <TabContent
        value={SharedVideoTabName.Details}
        className={videoStyles.sidebarContent}
      >
        <Details videoTitle={videoTitle} videoTestUrl={videoTestUrl} />
      </TabContent>
      <TaskTab
        currentVideoTime={currentVideoTime}
        videoDuration={videoDuration}
        isLegacyTask={isLegacyTask}
        onClickTime={onClickTime}
        taskStepsWithResponse={taskStepsWithResponse}
      />
      <TranscriptTab
        videoDuration={videoDuration}
        onClickTime={onClickTime}
        videoId={videoId}
        videoTranscript={videoTranscript}
        currentVideoSecond={currentVideoSecond}
      />
    </Tabs>
  );
}

/**


  Details Tab

*/
function Details({ videoTitle, videoTestUrl }: DetailsTabProps) {
  return (
    <div className={cn(videoStyles.detailsSection)}>
      <div className={`${videoStyles.detailsTitle}`}>{videoTitle}</div>
      <div className={`${videoStyles.detailsUrl}`}>{videoTestUrl}</div>
    </div>
  );
}

/**


  Task Tab

*/
function TaskTab(props: TaskTabProps) {
  const [isCurrentTaskItemVisibleState, setIsCurrentTaskItemVisibleState] =
    useState(true);

  const tasksScrollerRef = useRef(null);

  const handleChangeCurrentTaskItemVisible = useCallback(
    (isVisible: boolean) => {
      setIsCurrentTaskItemVisibleState(isVisible);
    },
    [],
  );

  return (
    <TabContentScroller
      value={SharedVideoTabName.Task}
      className={videoStyles.sidebarContent}
      ref={tasksScrollerRef}
    >
      <TaskTimeline
        {...props}
        onChangeCurrentTimelineItemVisible={handleChangeCurrentTaskItemVisible}
        preventScrollIntoView={!isCurrentTaskItemVisibleState}
        scrollerRef={tasksScrollerRef}
      />
    </TabContentScroller>
  );
}

/**


  Transcript Tab

*/
function TranscriptTab(props: TranscriptTabProps) {
  const [
    isCurrentTranscriptItemVisibleState,
    setIsCurrentTranscriptItemVisibleState,
  ] = useState(true);

  const transcriptScrollerRef = useRef(null);

  const handleChangeCurrentTranscriptItemVisible = useCallback(
    (isVisible: boolean) => {
      setIsCurrentTranscriptItemVisibleState(isVisible);
    },
    [],
  );

  return (
    <TabContentScroller
      value={SharedVideoTabName.Transcript}
      className={videoStyles.sidebarContent}
      ref={transcriptScrollerRef}
    >
      <Transcript
        {...props}
        isCurrentItemVisible={isCurrentTranscriptItemVisibleState}
        onChangeCurrentItemVisible={handleChangeCurrentTranscriptItemVisible}
        scrollerRef={transcriptScrollerRef}
      />
    </TabContentScroller>
  );
}
