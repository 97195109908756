import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { aleaPRNG } from "@/design-system/helpers";

import styles from "./styles.module.css";

const prng = aleaPRNG();
prng.int32();

const avatarColors = [
  "#5595a3",
  "#55a394",
  "#5d7f8d",
  "#5f5796",
  "#66a66c",
  "#6b677d",
  "#7073b5",
  "#8f7cb8",
  "#908da0",
  "#9f6495",
  "#a3b655",
  "#b2667c",
  "#bf8e60",
  "#c2605a",
  "#dfbb3b",
  "#e4765a",
  "#e48e69",
  "#e6a253",
  "#e8ae57",
];

/**
 * @param {string} [props.className] - Container element className.
 * @param {string} [props.initialsClassName] - Initials element className.
 * @param {string} props.initials - Text to be displayed.
 * @param {string} [props.starredIconClassName] - Starred icon element className.
 * @param {number|null|undefined} [props.colorSeed] - Background color seed value.
 * @param {boolean} [props.starred] - Controls the display of the starred icon element.
 * @param {Object} [props.style] - Container inline styles.
 * @param {ReactNode} [props.children] - Container children.
 * @param {Object} [props.containerProps] - Additional props to be spread over the container.
 */
export function Avatar(props) {
  const {
    className,
    initialsClassName,
    initials,
    starredIconClassName,
    colorSeed,
    starred,
    style,
    children,
    ...containerProps
  } = props;

  let localStyle = style || {};
  if (colorSeed != null) {
    prng.seed(colorSeed);
    const colorIndex = prng.range(0, avatarColors.length - 1);
    localStyle["--avatar-background-color"] = avatarColors[colorIndex];
    localStyle.color = "white";
  }

  return (
    <div
      className={cn(className, styles.avatar)}
      style={localStyle}
      title={initials}
      {...containerProps}
    >
      <span className={initialsClassName}>{initials}</span>
      {children}
      {!!starred && (
        <FontAwesomeIcon
          className={cn(styles.heartIcon, starredIconClassName)}
          icon={solid("heart")}
        />
      )}
    </div>
  );
}
