import React from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export default function ModalHeader(props) {
  const {
    className,
    icon,
    collapsed,
    heading,
    bigHeading,
    creditsAvailable,
    onClickBackButton,
    additionalInfoText,
    onClickCloseIcon,
    ...containerProps
  } = props;

  return (
    <div
      className={cn(
        className,
        styles.modalHeader,
        collapsed && styles.collapsed,
      )}
      {...containerProps}
    >
      <div className={styles.main}>
        {onClickCloseIcon && (
          <button onClick={onClickCloseIcon} className={styles.buttonCloseIcon}>
            Close
          </button>
        )}
        {typeof onClickBackButton === "function" && (
          <button className={styles.backButton} onClick={onClickBackButton}>
            <FontAwesomeIcon icon={regular("arrow-left")} fixedWidth />
          </button>
        )}
        {icon && (
          <FontAwesomeIcon icon={icon} fixedWidth className={styles.icon} />
        )}
        <div className={styles.mainText}>
          <h1 className={cn(styles.heading, bigHeading && styles.heading_big)}>
            {heading}
          </h1>
          {creditsAvailable !== undefined && !collapsed && (
            <div className={styles.credits}>
              Your credits: {creditsAvailable}
            </div>
          )}
        </div>
        {additionalInfoText && (
          <p className={styles.info}>{additionalInfoText}</p>
        )}
      </div>
      {collapsed && <span className={styles.fakeLink}>Show</span>}
    </div>
  );
}
