import React from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export default function Stack(props) {
  const { className, children, spacing, ...containerProps } = props;

  return (
    <div
      className={cn(
        className,
        spacing == null && styles.stack_default,
        spacing === "xsmall" && styles.stack_xsmall,
        spacing === "small" && styles.stack_small,
        spacing === "cards" && styles.stack_cards,
        spacing === "large" && styles.stack_large,
        spacing === "xlarge" && styles.stack_xlarge,
      )}
      {...containerProps}
    >
      {children}
    </div>
  );
}
