import React, { useMemo } from "react";
import { useContext } from "react";
import { cn } from "@sys42/utils";

import { TaskResultContext } from "..";
import { BarGraph } from "../BarGraph";
import { TaskResponseItem } from "../TaskResponseItem";
import { TaskResponseList } from "../TaskResponseList";
import { TaskResultsSummaryBox } from "../TaskResultsSummaryBox";
import { TaskTypeIcon } from "../TaskTypeIcon";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconSkipped } from "../skipped.svg";
import iconSingleChoice from "./single-choice.svg";

export function TaskResultsSingleChoice() {
  const {
    task,
    taskId,
    testId,
    testHasUnfinishedDeliveries,
    shownResponses,
    countResponses,
    countUpToDateResponses,
    stepNumber,
    averageTimeOnTask,
    showUpToDateOnly,
    onChangeShowUpToDateOnly,
    options,
  } = useContext(TaskResultContext);

  function getResponseText(response) {
    const differingTestStep = response.differing_teststep;
    const answers = differingTestStep
      ? differingTestStep.options.answers
      : options.answers;
    return answers[response.response.choice];
  }

  const bars = useMemo(() => {
    // All answers from current teststep
    let answersForGraph = options.answers;
    // Add answers from previous/differing teststeps
    const outdatedResponses = shownResponses.filter(
      (response) => !!response.differing_teststep,
    );
    for (const outdatedResponse of outdatedResponses) {
      const outdatedAnswers =
        outdatedResponse.differing_teststep.options.answers;
      for (const outdatedAnswer of outdatedAnswers) {
        if (answersForGraph.indexOf(outdatedAnswer) === -1) {
          answersForGraph.push(outdatedAnswer);
        }
      }
    }
    // Create array with all bars
    const bars = answersForGraph.map((answer) => ({
      label: answer,
      value: 0,
    }));
    // Fill bar values
    for (const response of shownResponses) {
      if (response.response.skipped === true) {
        continue;
      }

      let answer;
      if (response.differing_teststep) {
        answer =
          response.differing_teststep.options.answers[response.response.choice];
      } else {
        answer = options.answers[response.response.choice];
      }
      const bar = bars.find((bar) => bar.label === answer);
      if (bar) {
        bar.value++;
      }
    }
    return bars;
  }, [shownResponses, options]);

  return (
    <div className={styles.taskResultsSingleChoice}>
      <TaskResultsSummaryBox
        icon={<TaskTypeIcon taskType={"single_choice"} />}
        taskTypeName={"Single choice"}
        stepNumber={stepNumber}
        task={task}
        taskId={taskId}
        testId={testId}
        testHasUnfinishedDeliveries={testHasUnfinishedDeliveries}
        countShownResponses={shownResponses.length}
        countResponses={countResponses}
        countUpToDateResponses={countUpToDateResponses}
        showUpToDateOnly={showUpToDateOnly}
        onChangeShowUpToDateOnly={onChangeShowUpToDateOnly}
        averageTimeOnTask={averageTimeOnTask}
      >
        <BarGraph className={styles.barGraph} bars={bars} />
      </TaskResultsSummaryBox>
      <div className={styles.responses}>
        <TaskResponseList>
          {shownResponses.map((response, index) => {
            let differingTestStepTooltipContent = null;
            if (response.differing_teststep) {
              differingTestStepTooltipContent = (
                <>
                  <div>{response.differing_teststep.task}</div>
                  <ul className={styles.differingTeststepOptionsList}>
                    {response.differing_teststep.options.answers.map(
                      (answer, index) => (
                        <li key={index}>{answer}</li>
                      ),
                    )}
                  </ul>
                </>
              );
            }
            return (
              <TaskResponseItem
                key={index}
                video={response.video}
                timeOnTask={response.time_on_task}
                timestampCompleted={response.timestamp_completed}
                testId={testId}
                notes={response.notes}
                clips={response.clips}
                differingTestStepTooltipContent={
                  differingTestStepTooltipContent
                }
                className={cn(
                  !!response.differing_teststep &&
                    styles.taskResponseItem_outdated,
                )}
              >
                <div
                  className={cn(
                    styles.result,
                    response.response.skipped === true && styles.result_skipped,
                  )}
                >
                  {response.response.skipped === true ? (
                    <>
                      <SvgIconSkipped
                        height={undefined}
                        width={undefined}
                        className={styles.icon}
                      />{" "}
                      Skipped
                    </>
                  ) : (
                    <>
                      <img
                        className={styles.icon}
                        src={iconSingleChoice}
                        alt="Selected"
                      />{" "}
                      <div className={styles.resultText}>
                        {getResponseText(response)}
                      </div>
                    </>
                  )}
                </div>
              </TaskResponseItem>
            );
          })}
        </TaskResponseList>
      </div>
    </div>
  );
}
