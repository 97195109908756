import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import * as PropTypes from "prop-types";

import { Button } from "@/design-system";

import styles from "./styles.module.css";

export default function StepSeparator({ className, onClick }) {
  return (
    <div className={cn(className, styles.stepSeparator)}>
      <Button className={styles.addStepButton} type="button" onClick={onClick}>
        <FontAwesomeIcon icon={solid("plus")} /> Add task
      </Button>
    </div>
  );
}

StepSeparator.propTypes = { onClick: PropTypes.func };
