import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";
import RadioInput from "../RadioInput";

import styles from "./styles.module.css";

class RadioSelectItem extends React.Component {
  static defaultProps = { styles };

  render() {
    const {
      children,
      className,
      styles,
      value,
      name,
      checked,
      onChange,
      classNameChecked,
    } = this.props;

    return (
      <label
        {...extractContainerProps(this.props)}
        className={cn(
          className,
          styles.root,
          checked && styles.checked,
          checked && classNameChecked,
        )}
      >
        <RadioInput
          value={value}
          checked={checked}
          name={name}
          onChange={onChange}
          className={styles.radio}
        />
        <div className={styles.content}>
          <div className={styles.radioIndicator} />
          {typeof children === "string" ? <div>{children}</div> : children}
        </div>
      </label>
    );
  }
}

export default RadioSelectItem;
