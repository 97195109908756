import React from "react";
import { cn } from "@sys42/utils";
import uniqueId from "lodash/uniqueId";

import { extractContainerProps } from "../../helpers";
import RadioInput from "../RadioInput";

import styles from "./styles.module.css";

class RadioGroupItem extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.state = {
      uniqueId: uniqueId("input"),
    };
  }

  render() {
    const { className, disabled, styles, children, onChange, checked, value } =
      this.props;
    let { id } = this.props;
    if (!id) {
      id = this.state.uniqueId;
    }
    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root, disabled && styles.disabled)}
      >
        <RadioInput
          onChange={onChange}
          checked={checked}
          id={id}
          value={value}
          disabled={disabled}
        />
        <label htmlFor={id}>
          <div>{children}</div>
        </label>
      </div>
    );
  }
}

RadioGroupItem.propTypes = {};

export default RadioGroupItem;
