import React, { useRef, useState } from "react";
import { cn } from "@sys42/utils";
import ReactSlider from "react-slider";

import { SeekThumbnail } from "@/components/SeekThumbnail";

import styles from "./styles.module.css";

function calculateOffsetSeekThumbnailToStayInBounds(
  valueProgressSlider,
  seekThumbnail,
  playerProgress,
) {
  if (!seekThumbnail || !playerProgress) return 0;
  const { width: seekThumbnailWidth } = seekThumbnail.getBoundingClientRect();
  const { width: playerProgressWidth } = playerProgress.getBoundingClientRect();
  const progressInPixels = valueProgressSlider * playerProgressWidth;
  let offsetSeekThumbnail = 0;
  if (progressInPixels < seekThumbnailWidth / 2) {
    offsetSeekThumbnail = (progressInPixels / seekThumbnailWidth) * 100 - 50;
  }
  if (progressInPixels > playerProgressWidth - seekThumbnailWidth / 2) {
    offsetSeekThumbnail =
      ((progressInPixels - playerProgressWidth) / seekThumbnailWidth) * 100 +
      50;
    // Thank you copilot! I would have never figured this out on my own that fast!
    // I'm so glad I have you to help me out!
  }
  return offsetSeekThumbnail;
}

export function PlayerProgress(props) {
  const {
    className,
    onSeekTo,
    onSeekBefore,
    onSeekAfter,
    onChangeSeekThumbnailInfo,
    progress,
    duration,
    thumbnailSprite,
    vttUrl,
    ...restProps
  } = props;

  const [seeking, setSeeking] = useState(null);
  const [valueHoverProgressSlider, setValueHoverProgressSlider] = useState(0);
  const [mouseOverProgressSlider, setMouseOverProgressSlider] = useState(false);

  const refSeekThumbnail = useRef();
  const refPlayerProgress = useRef();

  const seekThumbnailPosition = seeking ?? valueHoverProgressSlider;
  const offsetSeekThumbnailToStayInBounds =
    calculateOffsetSeekThumbnailToStayInBounds(
      seekThumbnailPosition,
      refSeekThumbnail.current,
      refPlayerProgress.current,
    );

  function handleSeekChange(value) {
    if (seeking !== null) {
      setSeeking(value);
    }
  }

  function handleSeekBeforeChange(value) {
    setSeeking(value);
  }

  function handleSeekAfterChange() {
    setSeeking(null);
    onSeekTo(seeking);
    setMouseOverProgressSlider(false);
  }

  function handleSeekMouseEnter() {
    setMouseOverProgressSlider(true);
  }

  function handleSeekMouseLeave() {
    setMouseOverProgressSlider(false);
  }

  function handleTouchEnd() {
    // Fixes sticky hover state on mobile
    setMouseOverProgressSlider(false);
  }

  function handleSeekMouseMove(e) {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const offset = e.clientX - left;
    const percentage = offset / width;
    setValueHoverProgressSlider(percentage);
    if (!mouseOverProgressSlider) {
      setMouseOverProgressSlider(true);
    }
  }

  return (
    <div
      ref={refPlayerProgress}
      onTouchEnd={handleTouchEnd}
      className={cn(className, styles.playerProgress)}
      {...restProps}
    >
      <SeekThumbnail
        ref={refSeekThumbnail}
        style={{
          left: `${seekThumbnailPosition * 100}%`,
          transform: `translateX(-${50 + offsetSeekThumbnailToStayInBounds}%)`,
          display:
            mouseOverProgressSlider || seeking !== null ? "block" : "none",
        }}
        className={styles.seekThumbnail}
        thumbnailSprite={thumbnailSprite}
        vttUrl={vttUrl}
        time={seekThumbnailPosition * duration}
        onChangeThumbnailInfo={onChangeSeekThumbnailInfo}
      />
      <div
        onMouseEnter={handleSeekMouseEnter}
        onMouseLeave={handleSeekMouseLeave}
        onMouseMove={handleSeekMouseMove}
      >
        <ReactSlider
          className={styles.slider}
          thumbClassName={styles.sliderThumb}
          trackClassName={styles.sliderTrack}
          min={0}
          max={0.9999}
          step={0.0001}
          value={seeking !== null ? seeking : progress}
          onChange={handleSeekChange}
          onBeforeChange={handleSeekBeforeChange}
          onAfterChange={handleSeekAfterChange}
          renderTrack={(props) => <div {...props} />}
          renderThumb={(props) => <div {...props} />}
          // marks={[.2, .4, .6, .8]}
          // markClassName={styles.sliderMark}
          // renderMark={(props) => <div {...props}>
          //   <div className={styles.sliderMarkInner}>
          //     This is a very serious isssue here, the tester is completely wrong!
          //   </div>
          // </div>}
        />
      </div>
    </div>
  );
}
