import { useEffect, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";
import { cn } from "@sys42/utils";

import { FormField, InlineIcon, Tooltip } from "@/design-system";

import { CardBrandIcon } from "./CardBrandIcon";
import { SvgIconLock, SvgIconTooltip } from "./icons";
import {
  PaymentCard,
  PaymentCardBrand,
  paymentCardBrandUtils,
} from "./paymentCard";

import styles from "./styles.module.css";

export type PaymentFormProps = {
  card: PaymentCard | null;
  isChangeCardAllowed: boolean;
  onClickChangeCard: () => void;
  onChangeIsNewCardValid?: (isValid: boolean) => void;
  className?: string;
};

export function PaymentForm({
  card,
  isChangeCardAllowed,
  onClickChangeCard,
  onChangeIsNewCardValid,
  className,
}: PaymentFormProps) {
  return (
    <div className={className}>
      {card ? (
        <ExistingCard
          {...card}
          isChangeAllowed={isChangeCardAllowed}
          onClickChange={onClickChangeCard}
        />
      ) : (
        <NewCard onChangeIsValid={onChangeIsNewCardValid} />
      )}
    </div>
  );
}

function ExistingCard({
  brand,
  lastFourDigits,
  expiryMonth,
  expiryYear,
  isChangeAllowed,
  onClickChange,
  isExpired,
}: PaymentCard & { isChangeAllowed: boolean; onClickChange: () => void }) {
  return (
    <>
      <div className={styles.existingCardHeader}>
        <div className={styles.existingCardHeaderPaymentMethod}>
          Payment Method
        </div>
        <SecuredByStripe />
      </div>
      <div className={styles.existingCardBody}>
        <CardBrandIcon SvgIcon={paymentCardBrandUtils.getIcon(brand)} />
        <div className={styles.existingCardNumberAndExpiry}>
          <div className={styles.existingCardNumber}>
            {paymentCardBrandUtils.getDisplayName(brand)} ••••{lastFourDigits}
          </div>
          <div
            className={cn(
              styles.existingCardExpiry,
              isExpired && styles.existingCardExpiry_expired,
            )}
          >
            {"Expire" + (isExpired ? "d" : "s")} {expiryMonth}/{expiryYear}
          </div>
        </div>
        {isChangeAllowed && (
          <button onClick={onClickChange} className={styles.existingCardChange}>
            Change
          </button>
        )}
      </div>
    </>
  );
}

function getRootFontSize(): number {
  return parseFloat(
    window?.getComputedStyle(document.documentElement).fontSize,
  );
}

function getRootFontColor(): string {
  return window
    ?.getComputedStyle(document.documentElement)
    .getPropertyValue("--color-text-body");
}

function NewCard({
  onChangeIsValid,
}: {
  onChangeIsValid?: (isValid: boolean) => void;
}) {
  const [cardBrand, setCardBrand] = useState<PaymentCardBrand | null>(null);
  const [numberErrorMessage, setNumberErrorMessage] = useState<string | null>(
    null,
  );
  const [numberIsValid, setNumberIsValid] = useState(false);
  const [expiryDateErrorMessage, setExpiryDateErrorMessage] = useState<
    string | null
  >(null);
  const [expiryDateIsValid, setExpiryDateIsValid] = useState(false);
  const [securityCodeErrorMessage, setSecurityCodeErrorMessage] = useState<
    string | null
  >(null);
  const [securityCodeIsValid, setSecurityCodesValid] = useState(false);
  const isValid = numberIsValid && expiryDateIsValid && securityCodeIsValid;

  const [rootFontSize, setRootFontSize] = useState(() => getRootFontSize());
  const [rootFontColor] = useState(() => getRootFontColor());

  useEffect(() => {
    function handleWindowResize() {
      setRootFontSize(getRootFontSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function handleChangeNumber(e: StripeCardNumberElementChangeEvent) {
    setCardBrand(e.brand !== "unknown" ? e.brand : null);
    setNumberErrorMessage(e.error?.message || null);
    setNumberIsValid(e.complete && e.error === null);
  }

  function handleChangeExpiryDate(e: StripeCardExpiryElementChangeEvent) {
    setExpiryDateErrorMessage(e.error?.message || null);
    setExpiryDateIsValid(e.complete && e.error === null);
  }

  function handleChangeSecurityCode(e: StripeCardCvcElementChangeEvent) {
    setSecurityCodeErrorMessage(e.error?.message || null);
    setSecurityCodesValid(e.complete && e.error === null);
  }

  useEffect(() => {
    onChangeIsValid?.(isValid);
  }, [isValid, onChangeIsValid]);

  const stripeElementOptions: StripeCardNumberElementOptions = {
    style: {
      base: {
        color: rootFontColor,
        fontSize: `${rootFontSize}px`,
        "::placeholder": {
          color: "#BBBBCA",
        },
      },
      invalid: {
        color: rootFontColor,
      },
    },
  };

  return (
    <>
      <CardBrandIconStrip />
      <FormField
        className={styles.newCardInputNumberFormField}
        errorMessage={numberErrorMessage}
        label={
          <div className={styles.newCardInputNumberLabel}>
            card number <SecuredByStripe />
          </div>
        }
      >
        <div className={styles.inputWrapper}>
          {cardBrand && (
            <CardBrandIcon
              className={styles.newCardInputNumberCard}
              SvgIcon={paymentCardBrandUtils.getIcon(cardBrand)}
            />
          )}
          <CardNumberElement
            options={stripeElementOptions}
            className={cn(
              styles.newCardInputNumber,
              numberErrorMessage && styles.newCardInputNumber_error,
            )}
            onChange={handleChangeNumber}
          />
        </div>
      </FormField>
      <div className={styles.newCardInputExpiryAndCvcFormFields}>
        <FormField
          className={styles.newCardInputExpiryFormField}
          errorMessage={expiryDateErrorMessage}
          label={
            <>
              expiry date{" "}
              <Tooltip
                content="The month and year your card expires, as shown on the front. Format: MM/YY."
                isError={!!expiryDateErrorMessage}
              >
                <InlineIcon
                  className={styles.tooltipIcon}
                  Svg={SvgIconTooltip}
                />
              </Tooltip>
            </>
          }
        >
          <div className={styles.inputWrapper}>
            <CardExpiryElement
              options={stripeElementOptions}
              onChange={handleChangeExpiryDate}
              className={cn(
                styles.newCardInputExpiry,
                expiryDateErrorMessage && styles.newCardInputExpiry_error,
              )}
            />
          </div>
        </FormField>
        <FormField
          className={styles.newCardInputCvcFormField}
          errorMessage={securityCodeErrorMessage}
          label={
            <>
              security code{" "}
              <Tooltip content="The 3 or 4-digit code on the back of your card, near the signature space.">
                {" "}
                <InlineIcon
                  className={styles.tooltipIcon}
                  Svg={SvgIconTooltip}
                />
              </Tooltip>
            </>
          }
        >
          <div className={styles.inputWrapper}>
            <CardCvcElement
              options={stripeElementOptions}
              onChange={handleChangeSecurityCode}
              className={cn(
                styles.newCardInputCvc,
                securityCodeErrorMessage && styles.newCardInputCvc_error,
              )}
            />
          </div>
        </FormField>
      </div>
    </>
  );
}

function CardBrandIconStrip() {
  return (
    <div className={styles.paymentCards}>
      {paymentCardBrandUtils.allNames().map((brand) => (
        <CardBrandIcon
          className={styles.paymentCardsIcon}
          SvgIcon={paymentCardBrandUtils.getIcon(brand)}
          key={brand}
        />
      ))}
    </div>
  );
}

function SecuredByStripe() {
  return (
    <div className={styles.securedByStripe}>
      <InlineIcon Svg={SvgIconLock} />
      Secured by Stripe
    </div>
  );
}
