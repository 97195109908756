import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export function RatingStars(props) {
  const { className, rating, ...containerProps } = props;

  const ratingStars = [];
  for (let i = 1; i <= 5; i++) {
    if (i > rating) {
      ratingStars.push(
        <div key={i} className={`${styles.star} ${styles.star_empty}`}>
          <FontAwesomeIcon icon={solid("star")} key={i} />
        </div>,
      );
    } else {
      ratingStars.push(
        <div key={i} className={styles.star}>
          <FontAwesomeIcon icon={solid("star")} key={i} />
        </div>,
      );
    }
  }

  return (
    <div className={cn(className, styles.ratingStars)} {...containerProps}>
      {ratingStars}
    </div>
  );
}
