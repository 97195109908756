import React from "react";
import { cn } from "@sys42/utils";
import toLower from "lodash/toLower";

import { getWordingForTargetingValue } from "../../helpers";

import styles from "./styles.module.css";

export function TesterTargetingSummary(props) {
  const { className, tester, ...containerProps } = props;

  const country = tester?.country_of_residence;
  const age = tester?.age;
  const gender = tester?.gender;
  const testerType = tester?.type;

  const testerDetailsString = [
    country,
    gender && getWordingForTargetingValue("gender", gender),
    age && getWordingForTargetingValue("age", age),
    testerType === "invitation" && "From your own users",
  ]
    .filter((i) => !!i)
    .join(" · ");

  return (
    <div
      className={cn(className, styles.testerTargetingSummary)}
      title={testerDetailsString}
      {...containerProps}
    >
      {country?.length === 2 && (
        <img
          className={styles.flag}
          src={`/flags/1x1/${toLower(country)}.svg`}
          alt={country}
        />
      )}
      {testerDetailsString}
    </div>
  );
}
