import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import PropTypes from "prop-types";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class InputSearch extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.refInput = React.createRef;
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleClickClear = (e) => {
    e.preventDefault();
    this.clearInput();
    if (this.refInput.current) {
      this.refInput.current.focus(); // Todo: das geht net!!!
    }
  };

  handleMouseDownClear = (e) => {
    e.preventDefault();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.clearInput();
    }
  };

  clearInput() {
    this.props.onChange({ target: { value: "" } });
  }

  render() {
    const { className, onChange, placeholder, styles, value } = this.props;

    const showClearButton = !!value;

    return (
      <div
        className={cn(
          className,
          styles.container,
          showClearButton && styles.container_clearButtonVisible,
        )}
      >
        <input
          {...extractContainerProps(this.props)}
          className={styles.root}
          onChange={onChange}
          placeholder={placeholder}
          type="search"
          ref={this.refInput}
          value={value}
        />
        {showClearButton && (
          <button
            className={styles.clearButton}
            onClick={this.handleClickClear}
            onMouseDown={this.handleMouseDownClear}
          >
            <FontAwesomeIcon icon={solid("times-circle")} />
          </button>
        )}
      </div>
    );
  }
}

InputSearch.propTypes = {
  onChange: PropTypes.func,
};

export default InputSearch;
