import React from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export default function TabbedModalHeaderItem({
  className,
  children,
  active,
  ...containerProps
}) {
  return (
    <button
      {...containerProps}
      role="tab"
      className={cn(className, active && styles.tabActive, styles.tab)}
      aria-selected={active}
      tabIndex={active ? 0 : -1}
    >
      {children}
    </button>
  );
}

//https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
