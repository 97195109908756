import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class InfoBox extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, className, styles, type, icon } = this.props;
    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(
          className,
          styles.infoBox,
          type === "subtle" && styles.infoBox_subtle,
          type === "subtle-on-background" && styles.infoBox_subtleOnBackground,
        )}
      >
        {icon && <div className={styles.icon}>{icon}</div>}
        {!icon && !type && (
          <div className={styles.icon}>
            <FontAwesomeIcon icon={solid("info-circle")} fixedWidth />
          </div>
        )}
        <div className={styles.info}>{children}</div>
      </div>
    );
  }
}

export default InfoBox;
