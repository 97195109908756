import React from "react";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

export function ModalContent(props) {
  const { className, withModalHeader, ...containerProps } = props;

  return (
    <div
      className={cn(
        className,
        styles.modalContent,
        withModalHeader && styles.modalContent_withModalHeader,
      )}
      {...containerProps}
    />
  );
}
