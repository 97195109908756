import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class StepNavigationItem extends React.Component {
  static defaultProps = { styles };

  render() {
    const { active, className, children, styles, onClick } = this.props;

    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(
          className,
          styles.root,
          !!active && styles.active,
          !!onClick && styles.clickable,
        )}
      >
        {children}
      </div>
    );
  }
}

export default StepNavigationItem;
