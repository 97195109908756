import { cn } from "@sys42/utils";

import { ButtonPrimary } from "@/design-system";

import { formatPrice } from "./../../helpers";

import styles from "./styles.module.css";

import imgPlanIconStep from "./plan-icon-stack.jpg";

export default function ExportPlansPromo({
  onClick,
  className,
  startingAtPrice,
  savePercent,
  currency,
}) {
  return (
    <section className={cn("card", styles.exportPlansPromo, className)}>
      <img className={styles.image} src={imgPlanIconStep} alt="Our plans" />
      <div className={styles.content}>
        <div>
          <h1 className={styles.heading}>
            Save up to {savePercent}% with our flexible&nbsp;plans
          </h1>
          <p>
            Make regular testing a routine and save up to {savePercent}% with
            our subscription plans starting at just{" "}
            {formatPrice(startingAtPrice, currency)} a month.
          </p>
        </div>
        <ButtonPrimary onClick={onClick}>Explore our plans</ButtonPrimary>
      </div>
    </section>
  );
}
