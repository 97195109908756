import { ReactNode, useEffect, useState } from "react";
import { cn } from "@sys42/utils";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { ImageLoader } from "@/components/ImageLoader";
import { NoteWithHashtags } from "@/components/NoteWithHashtags";
import { DropDownDialog } from "@/design-system";
import { VideoClipOverview, VideoClipVideo } from "@/entities/video/clip";
import { toHHMMSS } from "@/helpers-ts";
import { useBaseUrl } from "@/hooks/useBaseUrl";
import { useFeatures } from "@/hooks/useFeatures";
import { VIDEO_CLIP_GET_REQUEST } from "@/reducers/videoClipGet";
import { VIDEO_CLIP_SHARE_REQUEST } from "@/reducers/videoClipShare";
import { useAppSelector } from "@/store";
import UpgradeNowMessage from "../UpgradeNowMessage";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClear } from "./icons/icon-clear.svg";
import { ReactComponent as SvgIconDropdown } from "./icons/icon-dropdown.svg";
import { ReactComponent as SvgIconLink } from "./icons/icon-link.svg";
import { ReactComponent as SvgIconPlay } from "./icons/icon-play.svg";
import { ReactComponent as SvgIconPrivate } from "./icons/icon-private.svg";
import { ReactComponent as SvgIconPublic } from "./icons/icon-public.svg";

const NOTE_MAX_LENGTH = 140;

/**
 * Share Clip
 */
export function ShareClip({
  videoPosterUrl,
  timestampEnd,
  timestampStart,
  note,
  author,
  videoId,
  id,
  isPublic,
  sharedHash,
  onClickClose,
  isVideoReady,
}: (VideoClipOverview | VideoClipVideo) & {
  onClickClose: () => void;
  videoId: number;
}) {
  const dispatch = useDispatch();
  const baseUrl = useBaseUrl();

  const features = useFeatures();

  const sharedUrl = sharedHash
    ? `${baseUrl}/shared/clips/${sharedHash}`
    : `${baseUrl}/video/${videoId}?clip=${id}`;

  // Refresh clip on first render.
  // Subsequent refreshes are handled by the isPublic
  // dropdown onChange which posts to the API.
  useEffect(() => {
    dispatch({ type: VIDEO_CLIP_GET_REQUEST, videoId, clipId: id });
  }, [dispatch, id, videoId]);

  return (
    <div className={styles.shareClip}>
      <Header onClickClose={onClickClose} />
      {features?.clips === false ? (
        <UpgradeNowBody onClickClose={onClickClose} />
      ) : (
        <>
          <Body
            videoPosterUrl={videoPosterUrl}
            timestampEnd={timestampEnd}
            timestampStart={timestampStart}
            note={note}
            author={author}
            sharedUrl={sharedUrl}
            isVideoReady={isVideoReady}
          />
          <Footer
            sharedUrl={sharedUrl}
            isPublic={isPublic}
            clipId={id}
            videoId={videoId}
          />
        </>
      )}
    </div>
  );
}

function UpgradeNowBody({ onClickClose }: { onClickClose: () => void }) {
  return (
    <div className={cn(styles.body, styles.body_noFooter)}>
      <UpgradeNowMessage onClickUpgrade={onClickClose}>
        You can create clips with one of our monthly & yearly plans.
      </UpgradeNowMessage>
    </div>
  );
}

/**
 * Select isPublic Option
 */
function SelectIsPublicOption({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) {
  return (
    <div
      className={cn(
        styles.selectIsPublicOption,
        isActive && styles.selectIsPublicOption_active,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

/**
 * Select if clip is public or private.
 */
function SelectIsPublic({
  isPublic,
  onClickOption,
}: {
  isPublic: boolean;
  onClickOption: (isPublic: boolean) => void;
}) {
  const labelElement = (
    <div className={styles.selectIsPublicIconLabelContainer}>
      {isPublic ? "Access: Anyone" : "Access: Restricted"} <SvgIconDropdown />
    </div>
  );

  return (
    <DropDownDialog
      align={"left"}
      label={labelElement}
      closeOnClick
      triggerButtonClassName={styles.selectIsPublicDropdownTrigger}
    >
      <div className={styles.selectIsPublicOptionsContainer}>
        <SelectIsPublicOption
          isActive={isPublic}
          onClick={() => onClickOption(true)}
        >
          Anyone
        </SelectIsPublicOption>
        <SelectIsPublicOption
          isActive={!isPublic}
          onClick={() => onClickOption(false)}
        >
          Restricted
        </SelectIsPublicOption>
      </div>
    </DropDownDialog>
  );
}

/**
 * Footer
 */
function Footer({
  isPublic,
  clipId,
  videoId,
  sharedUrl,
}: {
  isPublic: boolean;
  clipId: number;
  videoId: number;
  sharedUrl: string;
}) {
  const dispatch = useDispatch();

  const [isCopied, setIsCopied] = useState(false);
  const [isPublicState, setIsPublicState] = useState(isPublic);

  const isError = useAppSelector((state) => state.videoClipShare.isError);

  function share(isPublic: boolean) {
    // This endpoint will also refresh the clip.
    dispatch({
      type: VIDEO_CLIP_SHARE_REQUEST,
      videoId,
      clipId,
      isShared: isPublic,
    });
  }

  function handleSelectIsPublic(isPublicNew: boolean) {
    setIsPublicState(isPublicNew);
    setIsCopied(false);

    // Always call share, regardless if isPublic or not.
    // If not isPublic then access needs to be revoked.
    // Default is always private so also it will always be
    // called if changing to private.
    share(isPublicNew);
  }

  function handleClickCopy() {
    navigator.clipboard.writeText(sharedUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  }

  useEffect(() => {
    if (isError && isPublicState !== isPublic) {
      setIsPublicState(isPublic);
      setIsCopied(false);
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: "Failed to generate link.",
      });
    }
  }, [isError, isPublicState, isPublic, dispatch]);

  return (
    <div className={styles.footer}>
      <div className={styles.footerIconSelectContainer}>
        <div
          className={cn(
            styles.footerIconContainer,
            isPublicState && styles.footerIconContainer_public,
          )}
        >
          {isPublicState ? <SvgIconPublic /> : <SvgIconPrivate />}
        </div>
        <div className={styles.footerSelectDescriptionContainer}>
          <SelectIsPublic
            isPublic={isPublicState}
            onClickOption={handleSelectIsPublic}
          />
          <div className={styles.footerDescription}>
            {isPublicState && "Anyone with the link can view"}
            {!isPublicState && "Only people in your team can view"}
          </div>
        </div>
      </div>
      <button onClick={handleClickCopy} className={styles.footerCopyLinkButton}>
        <SvgIconLink />
        {isCopied ? "Copied!" : "Copy Link"}
      </button>
    </div>
  );
}

/**
 * Modal Header
 */
function Header({ onClickClose }: { onClickClose: () => void }) {
  return (
    <div className={styles.header}>
      <h4>Share this clip</h4>
      <button onClick={onClickClose}>
        <SvgIconClear />
      </button>
    </div>
  );
}

type BodyProps = Pick<
  VideoClipOverview,
  | "videoPosterUrl"
  | "timestampEnd"
  | "timestampStart"
  | "note"
  | "author"
  | "isVideoReady"
> &
  VideoPosterProps & { sharedUrl: string };

/**
 * Modal Body
 */
function Body({
  videoPosterUrl,
  timestampEnd,
  timestampStart,
  note,
  author,
  sharedUrl,
  isVideoReady,
}: BodyProps) {
  const truncatedNote =
    note.length > NOTE_MAX_LENGTH ? note.slice(0, NOTE_MAX_LENGTH) + "…" : note;

  return (
    <div className={styles.body}>
      <Link
        className={styles.bodyPosterButtonContainer}
        target="_blank"
        to={sharedUrl}
      >
        <VideoPoster
          videoPosterUrl={videoPosterUrl}
          timestampEnd={timestampEnd}
          timestampStart={timestampStart}
          isVideoReady={isVideoReady}
        />
        <span className={styles.bodyPreviewButton}>Preview in new tab</span>
      </Link>
      <div className={styles.bodyHeadingMetaContainer}>
        <div>
          <NoteWithHashtags
            note={truncatedNote}
            classNameHashtag={styles.bodyNoteTag}
          />
        </div>
        <div className={styles.bodyVideoMeta}>
          {toHHMMSS(timestampStart)} - {toHHMMSS(timestampEnd)} · by {author}
        </div>
      </div>
    </div>
  );
}

type VideoPosterProps = Pick<
  VideoClipOverview,
  "videoPosterUrl" | "timestampEnd" | "timestampStart" | "isVideoReady"
>;

/**
 * Video Poster
 */
function VideoPoster({
  videoPosterUrl,
  timestampEnd,
  timestampStart,
  isVideoReady,
}: VideoPosterProps) {
  return (
    <div className={styles.videoPoster}>
      {isVideoReady && videoPosterUrl && (
        <ImageLoader
          url={videoPosterUrl}
          imageClassName={styles.videoPosterImage}
        >
          <SvgIconPlay className={styles.videoPosterPlayIcon} />
          <div className={styles.videoPosterDuration}>
            {toHHMMSS(timestampEnd - timestampStart)}
          </div>
        </ImageLoader>
      )}
      {(!isVideoReady || !videoPosterUrl) && (
        <span className={styles.videoPosterGeneratingFeedback}>
          Generating…
        </span>
      )}
    </div>
  );
}
