import React from "react";
import { cn } from "@sys42/utils";
import { accessibleOnClick } from "@sys42/utils";
import PropTypes from "prop-types";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

import imgHidePasswordIcon from "./hidePasswordEye.svg";
import imgShowPasswordIcon from "./showPasswordEye.svg";

class InputPassword extends React.Component {
  static defaultProps = { styles, onChange: null, id: undefined };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  handleClickToggleShowPassword = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  render() {
    const {
      error,
      styles,
      value,
      onChange,
      placeholder,
      id,
      className,
      fullWidth,
      autoComplete,
      autoFocus,
      withShowPasswordToggle,
    } = this.props;

    const { showPassword } = this.state;

    let classNameInput = cn(
      styles.input,
      error && styles.error,
      withShowPasswordToggle && styles.input_withShowPasswordToggle,
    );

    return (
      <div
        className={cn(className, styles.root, fullWidth && styles.fullWidth)}
      >
        <input
          {...extractContainerProps(this.props)}
          className={classNameInput}
          onChange={onChange}
          type={showPassword ? "text" : "password"}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          value={value}
          placeholder={placeholder}
          id={id}
        />
        {withShowPasswordToggle === true && (
          <div
            className={styles.toggleShowPassword}
            {...accessibleOnClick(this.handleClickToggleShowPassword, -1)}
          >
            <img
              src={
                this.state.showPassword
                  ? imgHidePasswordIcon
                  : imgShowPasswordIcon
              }
              alt={this.state.showPassword ? "Hide Password" : "Show Password"}
            />
          </div>
        )}
      </div>
    );
  }
}

InputPassword.propTypes = {
  onChange: PropTypes.func,
};

export default InputPassword;
