import React from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class Tooltip extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.refIcon = React.createRef();
    this.refTooltip = React.createRef();
    this.state = {
      positionX: 0,
      maxWidth: "none",
      manuallyEnabledByClicking: false,
    };
  }

  refreshTooptipPosition() {
    if (this.refIcon.current && this.refTooltip.current) {
      // https://codepen.io/Mamboleoo/post/scrollbars-and-css-custom-properties
      const getScrollbarWidth = () => {
        // Create a temporary div container and append it into the body
        const container = document.createElement("div");
        // Append the container in the body
        document.body.appendChild(container);
        // Force scrollbar on the container
        container.style.overflow = "scroll";

        // Add ad fake div inside the container
        const inner = document.createElement("div");
        container.appendChild(inner);

        // Calculate the width based on the container width minus its child width
        const width = container.offsetWidth - inner.offsetWidth;
        // Remove the container from the body
        document.body.removeChild(container);

        return width;
      };
      // Get the scrollbar dimension
      const scrollbarWidth = getScrollbarWidth();
      // Set a custom property with the value we calculated
      // document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);

      // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
      const { left: leftIcon, width: widthIcon } =
        this.refIcon.current.getBoundingClientRect();
      const { width: widthTooltip } =
        this.refTooltip.current.getBoundingClientRect();
      if (this.props.center) {
        const offsetToCenter = 0 - widthTooltip / 2 + widthIcon / 2;
        const positionX = 0;
        // Todo: keep on screen even if centered
        /*const positionX = Math.min(0, document.documentElement.clientWidth - leftIcon - widthTooltip - offsetToCenter);*/
        this.setState({ positionX: positionX + offsetToCenter });
      } else {
        const positionX = Math.min(
          0,
          document.documentElement.clientWidth - leftIcon - widthTooltip,
        );
        this.setState({
          positionX,
          maxWidth: `calc(100vw - ${scrollbarWidth}px)`,
        });
      }
    }
  }

  componentDidMount() {
    this.refreshTooptipPosition();
    window.addEventListener("resize", this.onWindowResize);
    document.addEventListener("mouseup", this.handleMouseUp);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.content !== prevProps.content) {
      this.refreshTooptipPosition();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    document.removeEventListener("mouseup", this.handleMouseUp);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  onWindowResize = () => {
    this.refreshTooptipPosition();
  };

  handleMouseUp = (e) => {
    if (
      !this.refTooltip.current.contains(e.target) &&
      !this.refIcon.current.contains(e.target)
    ) {
      this.setState({ manuallyEnabledByClicking: false });
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27 /* ESC */) {
      this.setState({ manuallyEnabledByClicking: false });
    }
  };

  handleClick = (e) => {
    e.stopPropagation(); // Prevent any onclick event in the parent from firing
    e.preventDefault(); // Prevent any onclick event in the parent from firing
    this.setState((state) => ({
      manuallyEnabledByClicking: !state.manuallyEnabledByClicking,
    }));
  };

  handleClickTooltip = (e) => {
    e.stopPropagation(); // Prevent any onclick event in the parent from firing
    e.preventDefault(); // Prevent any onclick event in the parent from firing
  };

  handleClickClose = () => {
    // No need to stop propagation here as the tooltip containing this button will take care of this
    this.setState({ manuallyEnabledByClicking: false });
  };

  render() {
    const { children, content, styles, small, className, width } = this.props;

    const forceEnabled = this.state.manuallyEnabledByClicking && !small;

    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(
          className,
          styles.root,
          forceEnabled && styles.manuallyEnabled,
        )}
      >
        <div
          ref={this.refIcon}
          className={cn(
            styles.tooltipIcon,
            !children && styles.tooltipIcon_default,
          )}
          onClick={this.handleClick}
        >
          {children || <FontAwesomeIcon icon={regular("question-circle")} />}
        </div>
        <div className={styles.tooltipWrapper}>
          <div
            className={cn(styles.tooltip, small && styles.tooltip_small)}
            onClick={this.handleClickTooltip}
            ref={this.refTooltip}
            style={{
              left: this.state.positionX + "px",
              width,
              maxWidth: this.state.maxWidth,
            }}
          >
            {content}
            {forceEnabled && (
              <button
                className={styles.close}
                onClick={this.handleClickClose}
                title="Close tooltip"
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Tooltip;
