import React, { useContext } from "react";
import { cn } from "@sys42/utils";

import { TaskResultContext } from "..";
import { TaskResponseItem } from "../TaskResponseItem";
import { TaskResponseList } from "../TaskResponseList";
import { TaskResultsSummaryBox } from "../TaskResultsSummaryBox";
import { TaskTypeIcon } from "../TaskTypeIcon";
import { WordCloud } from "../WordCloud";

import styles from "./styles.module.css";

export function TaskResultsWrittenResponse(props) {
  const {
    task,
    taskId,
    testId,
    testHasUnfinishedDeliveries,
    shownResponses,
    countResponses,
    countUpToDateResponses,
    stepNumber,
    averageTimeOnTask,
    showUpToDateOnly,
    onChangeShowUpToDateOnly,
    testLanguage,
  } = useContext(TaskResultContext);

  return (
    <div className={styles.taskResultsWrittenResponse}>
      <TaskResultsSummaryBox
        icon={<TaskTypeIcon taskType={"written_response"} />}
        taskTypeName={"Written response"}
        stepNumber={stepNumber}
        task={task}
        taskId={taskId}
        testId={testId}
        testHasUnfinishedDeliveries={testHasUnfinishedDeliveries}
        countShownResponses={shownResponses.length}
        countResponses={countResponses}
        countUpToDateResponses={countUpToDateResponses}
        showUpToDateOnly={showUpToDateOnly}
        onChangeShowUpToDateOnly={onChangeShowUpToDateOnly}
        averageTimeOnTask={averageTimeOnTask}
      >
        <WordCloud
          className={styles.wordCloud}
          language={testLanguage}
          fullText={shownResponses
            .map((response) => response.response.response)
            .join(" ")}
        />
      </TaskResultsSummaryBox>
      <div className={styles.responses}>
        <TaskResponseList>
          {shownResponses.map((response, index) => {
            let differingTestStepTooltipContent = null;
            if (response.differing_teststep) {
              differingTestStepTooltipContent = (
                <>
                  <div>{response.differing_teststep.task}</div>
                </>
              );
            }
            return (
              <TaskResponseItem
                key={index}
                video={response.video}
                timeOnTask={response.time_on_task}
                timestampCompleted={response.timestamp_completed}
                testId={testId}
                notes={response.notes}
                clips={response.clips}
                differingTestStepTooltipContent={
                  differingTestStepTooltipContent
                }
                className={cn(
                  !!response.differing_teststep &&
                    styles.taskResponseItem_outdated,
                )}
              >
                <div className={styles.result}>
                  {response.response.response}
                </div>
              </TaskResponseItem>
            );
          })}
        </TaskResponseList>
      </div>
    </div>
  );
}
