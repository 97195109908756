import { useCallback, useState } from "react";
import { cn } from "@sys42/utils";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { VideoShared } from "@/entities/video/shared";
import { useSendVideoStats } from "@/hooks/useSendVideoStats";
import {
  VIDEO_ENDED,
  VIDEO_PAUSED,
  VideoStatsType,
} from "@/reducers/videoStatsReducer";

import { VideoPlayer } from "./components/VideoPlayer";
import { UserbrainVideoPlayerApi } from "./components/VideoPlayer/types";
import { TaskStep } from "./types";
import { useJumpPoints } from "./utils/useJumpPoints";

import styles from "./shared.module.css";
import videoStyles from "./styles.module.css";

/**


  SharedVideo Player

*/

type SharedPlayerProps = {
  video: VideoShared;
  onChangeCurrentSecond: (timestamp: number) => void;
  taskSteps: TaskStep[];
  currentVideoSecond: number;
  sharedHash: string;
  refPlayer: React.MutableRefObject<UserbrainVideoPlayerApi | null>;
};

export const SharedPlayer = ({
  video,
  onChangeCurrentSecond,
  taskSteps,
  currentVideoSecond,
  sharedHash,
  refPlayer,
}: SharedPlayerProps) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const searchParamValueT = searchParams.get("t");
  const initialVideoTime = searchParamValueT
    ? Number(searchParamValueT)
    : undefined;
  const [currentSecond, setCurrentSecond] = useState<number>();

  const handleVideoPause = useCallback(() => {
    dispatch({ type: VIDEO_PAUSED });
  }, [dispatch]);

  const handleVideoEnded = useCallback(() => {
    dispatch({ type: VIDEO_ENDED });
  }, [dispatch]);

  const { nextJumpPoint, previousJumpPoint } = useJumpPoints(
    taskSteps,
    currentVideoSecond,
    video?.duration,
  );

  const handleVideoSecondChange = useCallback(
    (second: number) => {
      setCurrentSecond(second);
      onChangeCurrentSecond(second);
    },
    [onChangeCurrentSecond],
  );

  useSendVideoStats(
    sharedHash,
    VideoStatsType.SharedVideo,
    currentSecond,
    refPlayer,
  );

  let initialTime = null;
  if (!isUndefined(initialVideoTime)) {
    initialTime = Math.min(video.duration, initialVideoTime);
  }

  return (
    <VideoPlayer
      className={cn(videoStyles.videoPlayer, styles.videoPlayer)}
      type={video.type}
      autoplay={searchParams.get("autoplay") === "1"}
      initialTime={initialTime}
      location={video.location}
      sources={video.sources}
      vtt={video.preview?.vtt}
      transcriptVttUrl={video.transcriptVttUrl}
      transcriptAvailable={video.transcript?.length > 0}
      thumbnailSprite={video.preview?.image}
      nextJumpPoint={nextJumpPoint}
      previousJumpPoint={previousJumpPoint}
      onPause={handleVideoPause}
      onEnded={handleVideoEnded}
      onSecondChange={handleVideoSecondChange}
      resumeKey={sharedHash}
      refPlayer={refPlayer}
    />
  );
};
