import React from "react";
import { cn } from "@sys42/utils";
import Select from "react-select";

import { dropDownStyles } from "./styles";

import styles from "./styles.module.css";

class DropDown extends React.Component {
  static defaultProps = { styles };

  onChange = (option) => {
    if (this.props.onChange) {
      this.props.onChange({
        target: { value: option === null ? null : option.value },
      });
    }
  };

  render() {
    const {
      children,
      className,
      fullWidth,
      styles,
      value,
      placeholder,
      isOptionDisabled,
      isSearchable,
      disabled,
    } = this.props;

    let found = false;
    const childrenAsArray = React.Children.toArray(children);
    const options = [];

    for (let i = 0; i < childrenAsArray.length; i++) {
      const child = childrenAsArray[i];
      if (child.props && child.props.value !== undefined) {
        options.push({ value: child.props.value, label: child.props.children });
      }
      if (child.props && child.props.value === value) {
        found = true;
      }
    }

    if (!found && value !== null && value !== undefined) {
      options.push({ value, label: value });
    }

    return (
      <>
        <Select
          className={cn(className, styles.root, fullWidth && styles.fullWidth)}
          onChange={this.onChange}
          options={options}
          isSearchable={!!isSearchable}
          placeholder={placeholder}
          isDisabled={disabled}
          value={options.find((option) => option.value === value)}
          isOptionDisabled={isOptionDisabled}
          styles={dropDownStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: "0.375em",
            colors: {
              ...theme.colors,
              neutral50: "#9393A5",
              primary: "#15E0AF",
              primary75: "#67F9D4",
              primary50: "#C7FFF4",
              primary25: "#E0FFF5",
            },
          })}
        />
      </>
    );
  }
}

export default DropDown;
