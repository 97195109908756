import { ReactNode } from "react";
import { cn } from "@sys42/utils";
import capitalize from "lodash/capitalize";

import { Skeleton } from "@/components/Skeleton";
import {
  creationModeAuto,
  creationModeScratch,
  creationModeTemplate,
  CreationType,
  getCreationMode,
  TestType,
} from "@/components/TestForm2/constants";
import { Button, ButtonPrimary, ErrorMessage, Tooltip } from "@/design-system";
import {
  urlTestSetupKnowledgeBase,
  urlUserTestingTemplates,
} from "@/helpers-ts";
import { Details } from "../Details";

import { SvgIconChevronRight } from "./icons";

import styles from "./styles.module.css";

type CardProps = {
  children: ReactNode;
  className?: string;
};

type PromptProps = {
  type: TestType;
  className?: string;
  description: string;
  fieldFeedback?: string[];
  errorMessage?: string | null;
  onChangeDescription: (description: string) => void;
  onClickCreate: () => void;
  isLoading: boolean;
};

export type AiTaskCreatorFeedbackEmotion = "sad" | "neutral" | "happy";

const descriptionExamples = [
  {
    text: "An online store specializing in electronic devices, offering a diverse selection of products ranging from smartphones to home appliances.",
    type: "website",
  },
  {
    text: "A fitness platform that provides users with workout plans, nutritional advice, and virtual coaching sessions.",
    type: "website",
  },
  {
    text: "A travel booking website that allows users to plan and book high-end vacations, including flights, hotels, and experiences.",
    type: "website",
  },
  {
    text: "A portfolio website for artists to showcase their work, including galleries, artist statements, and contact information.",
    type: "website",
  },
  {
    text: "A meal planning app that helps users create personalized meal plans, find recipes, and generate grocery shopping lists.",
    type: "app",
  },
  {
    text: "A personal finance app that enables users to track their expenses, set budgets, and monitor their savings goals.",
    type: "app",
  },
  {
    text: "A language learning app that offers interactive lessons, quizzes, and practice exercises for learning new languages.",
    type: "app",
  },
  {
    text: "A mindfulness meditation app that provides guided meditation sessions, breathing exercises, and daily mindfulness reminders.",
    type: "app",
  },
  {
    text: "A prototype for a smart home control app that enables users to manage their home’s lighting, security, and temperature from a single interface.",
    type: "prototype",
  },
  {
    text: "A prototype for a landing page for a project management SaaS tool that highlights features like task tracking, team collaboration, and reporting.",
    type: "prototype",
  },
  {
    text: "A prototype for a healthcare app that connects patients with doctors for virtual consultations and prescription management.",
    type: "prototype",
  },
  {
    text: "A prototype for a pricing page for a streaming service that outlines different subscription options, including basic, standard, and premium plans.",
    type: "prototype",
  },
];

const creationModes = [
  creationModeScratch,
  creationModeAuto,
  creationModeTemplate,
];

export function SelectCreationType({
  onSelectCreationType: onChange,
  className,
}: {
  onSelectCreationType: (mode: CreationType) => void;
  className?: string;
}) {
  return (
    <>
      <div className={cn(styles.selectCreationType, className)}>
        {creationModes.map((creationMode) => {
          const Icon = creationMode.iconLarge;
          return (
            <button
              onClick={() => onChange(creationMode.creationType)}
              key={creationMode.creationType + "_card"}
              className={styles.selectCreationTypeCard}
            >
              <Icon />
              <div className={styles.selectCreationTypeCardTitle}>
                {creationMode.cardTitle}
              </div>
              <div className={styles.selectCreationTypeCardDescription}>
                {creationMode.cardDescription}
              </div>
              <div className={styles.selectCreationTypeCardButton}>
                <SvgIconChevronRight />
                <span>Select</span>
              </div>
            </button>
          );
        })}
      </div>
      <div className={styles.selectCreationTypeFooter}>
        Explore{" "}
        <a
          href={urlUserTestingTemplates}
          target="_blank"
          rel="noopener noreferrer"
        >
          user testing examples
        </a>{" "}
        or{" "}
        <a
          href={urlTestSetupKnowledgeBase}
          target="_blank"
          rel="noopener noreferrer"
        >
          visit our knowledge base
        </a>{" "}
        for guidance.
      </div>
    </>
  );
}

export function StartOverBanner({
  creationType,
  onClickStartOver,
  className,
  isButtonDisabled,
}: {
  className?: string;
  creationType: CreationType;
  onClickStartOver: () => void;
  isButtonDisabled: boolean;
}) {
  const creationMode = getCreationMode(creationType);
  const Icon = creationMode.iconSmall;

  return (
    <Banner
      className={className}
      icon={<Icon />}
      title={creationMode.bannerTitle}
      description={creationMode.bannerDescription}
      slot={
        <Button
          disabled={isButtonDisabled}
          className={styles.updateButton}
          onClick={onClickStartOver}
        >
          Start over
        </Button>
      }
    />
  );
}

export function AiCreatorLoadingBanner({ className }: { className?: string }) {
  const Icon = creationModeAuto.iconSmall;
  return (
    <Banner
      className={className}
      icon={<Icon />}
      title={"Creating tasks…"}
      description={
        "We’re creating your tasks based on your description and the Test URL above."
      }
    />
  );
}

export function AiCreatorSkeleton({ className }: { className?: string }) {
  return (
    <div className={cn("card", className, styles.skeleton)}>
      <Skeleton className={styles.skeletonTitle} />
      <Skeleton className={styles.skeletonDescription} />
    </div>
  );
}

export function AiCreatorPrompt({
  type,
  className,
  description,
  onChangeDescription,
  onClickCreate,
  fieldFeedback,
  errorMessage,
  isLoading,
}: PromptProps) {
  const allExamples = descriptionExamples.filter(
    (example) => example.type === type,
  );

  const firstExample = allExamples[0];
  const restExamples = allExamples.slice(1);

  return (
    <CardPrimary className={className}>
      {errorMessage && (
        <ErrorMessage className={styles.promptErrorMessage}>
          {errorMessage}
        </ErrorMessage>
      )}
      {fieldFeedback?.map((errorMessage, index) => (
        <ErrorMessage className={styles.promptErrorMessage} key={index}>
          {errorMessage}
        </ErrorMessage>
      ))}
      <div className={styles.promptTitle}>
        {capitalize(type)} Description{" "}
        <Tooltip
          content="In addition to this description, the Test URL above is also taken into
        account when auto-creating the tasks."
        />
      </div>
      <textarea
        className={styles.promptInputDescription}
        value={description}
        autoFocus
        onChange={(e) => onChangeDescription(e?.target?.value)}
        placeholder={`Example: ${firstExample.text}`}
      />
      <div className={styles.promptInputDescriptionHint}>
        Briefly describe what your {type} is about.
      </div>
      <Details className={styles.examples} summary="Examples">
        <ul>
          {restExamples.map((example, index) => (
            <li key={index}>{example.text}</li>
          ))}
        </ul>
      </Details>
      <ButtonPrimary
        disabled={isLoading}
        className={styles.promptButton}
        onClick={onClickCreate}
      >
        {isLoading ? "Creating tasks…" : "Create tasks"}
      </ButtonPrimary>
    </CardPrimary>
  );
}

function Banner({
  className,
  icon,
  title,
  description,
  slot,
}: {
  className?: string;
  icon: ReactNode;
  title: ReactNode | string;
  description: ReactNode | string;
  slot?: ReactNode;
}) {
  return (
    <CardSecondary className={cn(className, styles.banner)}>
      <div className={styles.bannerIcon}>{icon}</div>
      <div className={styles.bannerTitleAndDescription}>
        <div className={styles.bannerTitle}>{title}</div>
        <div className={styles.bannerDescription}>{description}</div>
      </div>
      {slot}
    </CardSecondary>
  );
}

function CardSecondary({ children, className }: CardProps) {
  return <div className={cn(styles.cardSecondary, className)}>{children}</div>;
}

function CardPrimary({ children, className }: CardProps) {
  return (
    <div className={cn("card", className, styles.cardPrimary)}>{children}</div>
  );
}
