import React, { useEffect, useMemo, useState } from "react";
import { cn } from "@sys42/utils";
import { TagCloud } from "react-tagcloud";

import englishStopWords from "./english.txt";
import germanStopWords from "./german.txt";
import spanishStopWords from "./spanish.txt";

import styles from "./styles.module.css";

export function WordCloud(props) {
  const { className, fullText, language, ...containerProps } = props;

  const [stopWords, setStopWords] = useState([]);

  useEffect(() => {
    let pathToStopWords;
    switch (language) {
      case "en":
        pathToStopWords = englishStopWords;
        break;
      case "de":
        pathToStopWords = germanStopWords;
        break;
      case "es":
        pathToStopWords = spanishStopWords;
        break;
      default:
        pathToStopWords = null;
    }
    if (pathToStopWords !== null) {
      fetch(englishStopWords)
        .then((r) => r.text())
        .then((text) => setStopWords(text.split("\n")))
        .catch(() => setStopWords([]));
    }
  }, [language]);

  const data = useMemo(() => {
    const fullTextStripped = fullText
      .replace(/[’]/g, "'") // replace ’ with '
      .replace(/[^\p{Letter}\p{Mark}\s']/gu, "") // remove everything that is not a letter or '
      .replace(/\s+/g, " ") // remove multiple spaces
      .toLocaleLowerCase(); // To lower case

    return fullTextStripped
      .split(" ")
      .reduce((acc, word) => {
        if (stopWords.find((stopWord) => stopWord === word) != null) {
          return acc;
        }
        const found = acc.find(
          (i) => i.value.toLocaleLowerCase() === word.toLocaleLowerCase(),
        );
        if (found) {
          found.count++;
        } else {
          acc.push({ value: word, count: 1 });
        }
        return acc;
      }, [])
      .sort((a, b) => b.count - a.count)
      .slice(0, 15);
  }, [fullText, stopWords]);

  return (
    <div className={cn(className, styles.wordCloud)} {...containerProps}>
      <TagCloud
        minSize={14}
        maxSize={48}
        tags={data}
        disableRandomColor={true}
      />
    </div>
  );
}
