import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { getWordingForTargetingValue } from "../../helpers";

export function DeviceIconAndName(props) {
  const { className, device, ...containerProps } = props;

  return (
    <div className={cn(className)} {...containerProps}>
      {device?.type === "mobile" && (
        <>
          <FontAwesomeIcon icon={solid("mobile-alt")} fixedWidth />
          &nbsp;
        </>
      )}
      {device?.type === "desktop" && (
        <>
          <FontAwesomeIcon icon={solid("desktop")} fixedWidth />
          &nbsp;
        </>
      )}
      {device?.type === "tablet" && (
        <>
          <FontAwesomeIcon icon={solid("tablet-alt")} fixedWidth />
          &nbsp;
        </>
      )}
      {getWordingForTargetingValue("device-alt", device?.type)}
    </div>
  );
}
