import React from "react";
import { cn } from "@sys42/utils";
import PropTypes from "prop-types";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class RadioInput extends React.Component {
  static defaultProps = { styles, onChange: null };

  render() {
    const { className, disabled, id, styles, value, checked, onChange, name } =
      this.props;
    return (
      <input
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root)}
        onChange={onChange}
        name={name}
        id={id}
        type="radio"
        checked={checked}
        disabled={disabled}
        value={value}
      />
    );
  }
}

RadioInput.propTypes = {
  onChange: PropTypes.func,
};

export default RadioInput;
