import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class Paragraph extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, className, styles } = this.props;
    return (
      <p
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root)}
      >
        {children}
      </p>
    );
  }
}

export default Paragraph;
