import { useEffect, useState } from "react";
import { cn } from "@sys42/utils";

import { usePrevious } from "@/helpers-ts";

import { SvgIconLoading, SvgIconSuccess } from "./icons";

import styles from "./styles.module.css";

export function AiCreatorStatusIndicator({
  isAiTestSetupFetching,
  isAiTestSetupSuccess,
  isHidden,
}: {
  isAiTestSetupFetching: boolean;
  isAiTestSetupSuccess: boolean;
  isHidden?: boolean;
}) {
  const [status, setStatus] = useState<"hidden" | "loading" | "success">(
    "hidden",
  );

  const previousIsAiTestSetupFetching = usePrevious(isAiTestSetupFetching);

  useEffect(() => {
    if (
      previousIsAiTestSetupFetching === true &&
      isAiTestSetupFetching === false
    ) {
      if (isAiTestSetupSuccess === true) {
        setStatus("success");
      } else {
        setStatus("hidden");
      }
    } else if (
      previousIsAiTestSetupFetching === false &&
      isAiTestSetupFetching === true
    ) {
      setStatus("loading");
    }
  }, [
    previousIsAiTestSetupFetching,
    isAiTestSetupFetching,
    isAiTestSetupSuccess,
    status,
  ]);

  useEffect(() => {
    if (status === "success") {
      let timeout = setTimeout(() => {
        setStatus("hidden");
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [status]);

  if (status === "hidden" || isHidden === true) {
    return null;
  } else {
    return (
      <div
        className={cn(
          styles.statusIndicator,
          status === "success" && styles.statusIndicator_success,
        )}
        role="status"
        aria-live="polite"
        aria-label={status === "loading" ? "Loading" : "Success"}
      >
        {status === "loading" ? <SvgIconLoading /> : <SvgIconSuccess />}
        {status === "loading" ? "Creating tasks…" : "Your tasks are ready"}
        {status === "success" && (
          <button onClick={() => setStatus("hidden")}>Close</button>
        )}
      </div>
    );
  }
}
