import React from "react";
import { cn } from "@sys42/utils";
import PropTypes from "prop-types";

import { extractContainerProps, noop } from "../../helpers";

import styles from "./styles.module.css";

class DropDownDialog extends React.Component {
  static defaultProps = {
    label: "Unlabeled",
    onClose: noop,
    onOpen: noop,
    styles,
    align: "right",
    appearance: "button", // template styles for button
    triggerButtonClassName: "", // if provided, appearance is ignored and default styles are not applied.
    closeOnClick: false,
  };

  static propTypes = {
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      refDialog: null,
    };
    this.refWrapper = React.createRef();
  }

  handleSetRefDialog = (refDialog) => {
    this.setState({ refDialog }, () => this.refreshTooptipPosition());
  };

  handleClickContent = () => {
    if (this.props.closeOnClick === true) {
      setTimeout(() => this.close(), 0);
    }
  };

  refreshTooptipPosition = () => {
    if (this.refWrapper.current && this.state.refDialog) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
      const { right: rightWrapper, left: leftWrapper } =
        this.refWrapper.current.getBoundingClientRect();
      const { width: widthDialog } =
        this.state.refDialog.getBoundingClientRect();
      let positionX;
      if (this.props.align === "left") {
        positionX = Math.min(
          0,
          (widthDialog - (document.documentElement.clientWidth - leftWrapper)) *
            -1,
        );
      } else {
        positionX = Math.min(0, (widthDialog - rightWrapper) * -1);
      }
      this.setState({ positionX });
    }
  };

  onWindowResize = () => {
    this.refreshTooptipPosition();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (typeof nextProps.open !== "undefined") {
      return { open: !!nextProps.open };
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.onWindowResize);
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleClickOutside = (e) => {
    if (
      this.refWrapper.current &&
      !this.refWrapper.current.contains(e.target)
    ) {
      this.close();
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27 /* ESC */) {
      this.close();
    }
  };

  toggle = () => (this.state.open ? this.close() : this.open());

  close = (cb) => {
    if (typeof this.props.open === "undefined") {
      this.setState({ open: false }, cb);
    }
    this.props.onClose();
  };

  open = () => {
    if (typeof this.props.open === "undefined") {
      this.setState({ open: true });
    }
    this.props.onOpen();
  };

  render() {
    const {
      children,
      className,
      label,
      styles,
      align,
      appearance,
      triggerButtonClassName,
    } = this.props;
    const { open } = this.state;

    let contentStyle;
    if (align === "left") {
      contentStyle = { left: "" + this.state.positionX + "px" };
    } else {
      contentStyle = { right: "" + this.state.positionX + "px" };
    }
    // Default styles for trigger button and optional extra
    // template styles specified by the apperance prop.
    const defaultButtonStyles = cn(
      appearance === "button" && styles.triggerButton,
      appearance === "button-primary" && styles.triggerButtonPrimary,
      appearance === "select" && styles.triggerSelect,
      styles.trigger,
    );
    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root, open && styles.open)}
        ref={this.refWrapper}
      >
        <button
          onClick={this.toggle}
          ref={this.refTrigger}
          className={triggerButtonClassName || defaultButtonStyles}
        >
          {label}
        </button>
        {open && (
          <div
            onClick={this.handleClickContent}
            className={cn(
              styles.content,
              align === "left" && styles.contentAlignLeft,
            )}
            ref={this.handleSetRefDialog}
            style={contentStyle}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default DropDownDialog;
