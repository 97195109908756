import React, { useContext, useMemo } from "react";
import { cn } from "@sys42/utils";

import { TaskResultContext } from "..";
import { BarGraphRatingScale } from "../BarGraphRatingScale";
import { TaskResponseItem } from "../TaskResponseItem";
import { TaskResponseList } from "../TaskResponseList";
import { TaskResultsSummaryBox } from "../TaskResultsSummaryBox";
import { TaskTypeIcon } from "../TaskTypeIcon";

import styles from "./styles.module.css";

function RatingVis({ scaleStart, scaleEnd, rating }) {
  const dots = [];
  for (let i = scaleStart; i <= scaleEnd; i++) {
    dots.push(i);
  }
  return (
    <div className={styles.ratingVis}>
      {dots.map((dot) => (
        <div
          key={dot}
          className={cn(
            styles.ratingVisDot,
            dot === rating && styles.ratingVisDot_filled,
          )}
        ></div>
      ))}
    </div>
  );
}

export function TaskResultsRatingScale(props) {
  const {
    task,
    taskId,
    testId,
    testHasUnfinishedDeliveries,
    shownResponses,
    countResponses,
    countUpToDateResponses,
    stepNumber,
    averageTimeOnTask,
    averageRating,
    showUpToDateOnly,
    onChangeShowUpToDateOnly,
    options,
  } = useContext(TaskResultContext);

  const [bars, minScaleStart, maxScaleEnd] = useMemo(() => {
    // Scale start and end from current teststep
    let minScaleStart = options.scale_start;
    let maxScaleEnd = options.scale_end;
    // Find highest/lowest scale for previous/differing teststeps
    const outdatedResponses = shownResponses.filter(
      (response) => !!response.differing_teststep,
    );
    for (const outdatedResponse of outdatedResponses) {
      minScaleStart = Math.min(
        minScaleStart,
        outdatedResponse.differing_teststep.options.scale_start,
      );
      maxScaleEnd = Math.max(
        maxScaleEnd,
        outdatedResponse.differing_teststep.options.scale_end,
      );
    }
    // Create array with all bars
    const bars = [];
    for (let i = minScaleStart; i <= maxScaleEnd; i++) {
      bars.push({
        value: shownResponses.reduce(
          (acc, cur) => (cur.response.rating === i ? acc + 1 : acc),
          0,
        ),
      });
    }
    return [bars, minScaleStart, maxScaleEnd];
  }, [shownResponses, options]);

  return (
    <div className={styles.taskResultsRatingScale}>
      <TaskResultsSummaryBox
        icon={<TaskTypeIcon taskType={"rating_scale"} />}
        taskTypeName={"Rating scale"}
        stepNumber={stepNumber}
        task={task}
        taskId={taskId}
        testId={testId}
        testHasUnfinishedDeliveries={testHasUnfinishedDeliveries}
        countShownResponses={shownResponses.length}
        countResponses={countResponses}
        countUpToDateResponses={countUpToDateResponses}
        showUpToDateOnly={showUpToDateOnly}
        onChangeShowUpToDateOnly={onChangeShowUpToDateOnly}
        averageTimeOnTask={averageTimeOnTask}
        averageRating={averageRating}
      >
        <BarGraphRatingScale
          className={styles.barGraphRatingScale}
          labelStart={`${minScaleStart} — ${options.scale_start_label}`}
          labelEnd={`${options.scale_end_label} — ${maxScaleEnd}`}
          bars={bars}
        />
      </TaskResultsSummaryBox>
      <div className={styles.responses}>
        <TaskResponseList>
          {shownResponses.map((response, index) => {
            let differingTestStepTooltipContent = null;
            if (response.differing_teststep) {
              differingTestStepTooltipContent = (
                <>
                  <div>{response.differing_teststep.task}</div>
                  <div>
                    {response.differing_teststep.options.scale_start_label}(
                    {response.differing_teststep.options.scale_start}) –{" "}
                    {response.differing_teststep.options.scale_end_label}(
                    {response.differing_teststep.options.scale_end})
                  </div>
                </>
              );
            }
            return (
              <TaskResponseItem
                key={index}
                video={response.video}
                timeOnTask={response.time_on_task}
                timestampCompleted={response.timestamp_completed}
                testId={testId}
                notes={response.notes}
                clips={response.clips}
                differingTestStepTooltipContent={
                  differingTestStepTooltipContent
                }
                className={cn(
                  !!response.differing_teststep &&
                    styles.taskResponseItem_outdated,
                )}
              >
                <div className={styles.result}>
                  <RatingVis
                    scaleStart={
                      response.differing_teststep?.options.scale_start ??
                      options.scale_start
                    }
                    scaleEnd={
                      response.differing_teststep?.options.scale_end ??
                      options.scale_end
                    }
                    rating={response.response.rating}
                  />
                  <div className={styles.rating}>
                    {response.response.rating} of{" "}
                    {response.differing_teststep?.options.scale_end ??
                      options.scale_end}
                  </div>
                </div>
              </TaskResponseItem>
            );
          })}
        </TaskResponseList>
      </div>
    </div>
  );
}
