import React from "react";
import { cn } from "@sys42/utils";
import { Link } from "react-router-dom";

import { extractContainerProps } from "../../helpers";
import MainNavigationAccountItem from "../MainNavigationAccountItem";

class MainNavigationAccountItemLink extends MainNavigationAccountItem {
  static defaultProps = { ...super.defaultProps, to: "/" };

  render() {
    const { children, styles, to, className, href, target } = this.props;

    if (href) {
      return (
        <a
          {...extractContainerProps(this.props)}
          className={cn(className, styles.root)}
          href={href}
          target={target}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root)}
        to={to}
      >
        {children}
      </Link>
    );
  }
}

export default MainNavigationAccountItemLink;
