import React, { forwardRef, useEffect, useState } from "react";
import { cn } from "@sys42/utils";

import { getCues } from "../../helpers";
import { toHHMMSS } from "../../helpers-ts";

import styles from "./styles.module.css";

export const SeekThumbnail = forwardRef((props, forwardedRef) => {
  const {
    time,
    thumbnailSprite,
    vttUrl,
    className,
    onChangeThumbnailInfo,
    ...restProps
  } = props;

  const [cues, setCues] = useState(null);
  const [currentCue, setCurrentCue] = useState(null);

  useEffect(() => {
    if (!vttUrl) return;
    getCues(vttUrl).then(setCues).catch(console.error);
  }, [vttUrl]);

  useEffect(() => {
    if (!cues) return;
    const cue = cues?.find(
      (cue) => cue.startTime <= time && cue.endTime >= time,
    );
    if (cue) {
      setCurrentCue(cue);
    }
  }, [cues, time]);

  const cueText = currentCue?.text;
  const cueArray = cueText?.substring(6).split(",");
  const height = cueArray?.[3];
  const width = cueArray?.[2];
  const offsetX = cueArray?.[0];
  const offsetY = cueArray?.[1];

  useEffect(() => {
    if (typeof onChangeThumbnailInfo === "function") {
      onChangeThumbnailInfo({
        spriteUrl: thumbnailSprite,
        height,
        width,
        offsetX,
        offsetY,
      });
    }
  }, [onChangeThumbnailInfo, thumbnailSprite, height, width, offsetX, offsetY]);

  return (
    <div
      className={cn(styles.seekThumbnail, className)}
      {...restProps}
      ref={forwardedRef}
    >
      <div
        className={styles.seekThumbnailImage}
        style={{
          height: `${height}px`,
          width: `${width}px`,
          backgroundImage: `url(${thumbnailSprite})`,
          backgroundPosition: `-${offsetX}px -${offsetY}px`,
        }}
      />
      <div className={styles.seekThumbnailTime}>
        {toHHMMSS(time, { trimLeadingZero: true })}
      </div>
    </div>
  );
});
