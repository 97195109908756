import { cn } from "@sys42/utils";
import Select, {
  ControlProps,
  IndicatorsContainerProps,
  MenuProps,
  MultiValue,
  OptionProps,
  SingleValue,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";

import styles from "./styles.module.css";

export type NavigationMenuOption = {
  value: number;
  label: string;
  isLocked?: boolean;
  isNotification?: boolean;
  icon?: string;
};

// XXX: The library makes use of a few data-attributes. For example
// :focus is implemented using data-focused. I have continued this
// pattern for these custom components, but I'm wondering if it would be
// better to assign the classnames directly instead.
const CUSTOM_COMPONENTS = {
  Control: ({
    children,
    innerProps,
    innerRef,
    menuIsOpen,
    isFocused,
  }: ControlProps<NavigationMenuOption>) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={styles.control}
      aria-expanded={menuIsOpen}
      data-focused={isFocused}
    >
      {children}
    </div>
  ),
  Option: ({
    innerRef,
    innerProps,
    label,
    data,
    isSelected,
    isFocused,
  }: OptionProps<NavigationMenuOption>) => {
    return (
      <div
        {...innerProps}
        ref={innerRef}
        data-locked={data.isLocked}
        className={styles.option}
        aria-selected={isSelected}
        data-focused={isFocused}
      >
        <picture data-notification={data.isNotification}>
          <img src={data.icon} alt={`${label} icon`} />
        </picture>
        {label}
      </div>
    );
  },
  SingleValue: ({ children, data }: SingleValueProps<NavigationMenuOption>) => (
    <div className={styles.singleValue}>
      <picture data-notification={data.isNotification}>
        <img src={data.icon} alt={`${children} icon`} />
      </picture>
      {children}
    </div>
  ),
  // Remove the wrapping div
  ValueContainer: ({ children }: ValueContainerProps<NavigationMenuOption>) => (
    <>{children}</>
  ),
  IndicatorsContainer: (_: IndicatorsContainerProps<NavigationMenuOption>) =>
    null,
  Menu: ({
    children,
    innerProps,
    innerRef,
  }: MenuProps<NavigationMenuOption>) => (
    <div ref={innerRef} {...innerProps} className={styles.menu}>
      {children}
    </div>
  ),
};

export function MenuNavigation({
  className,
  options,
  onChange,
  value,
}: {
  className?: string;
  options: NavigationMenuOption[];
  onChange: (value: number) => void;
  value: number;
}) {
  const handleChange = (
    option:
      | MultiValue<NavigationMenuOption>
      | SingleValue<NavigationMenuOption>,
  ) => {
    if (option !== null && "value" in option) {
      onChange?.(option.value);
    }
  };

  return (
    <Select
      onChange={handleChange}
      defaultValue={options[value]}
      components={CUSTOM_COMPONENTS}
      className={cn(className, styles.menuNavigation)}
      options={options}
      isSearchable={false}
      isClearable={false}
    />
  );
}
