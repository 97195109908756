import React from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import { Draggable } from "react-beautiful-dnd";

import { InputText } from "@/design-system";

import styles from "./styles.module.css";

export default function ChoiceAnswer(props) {
  const {
    className,
    value,
    onChange,
    index,
    stepIndex,
    placeholder,
    choiceType,
    onClickDelete,
    hideDragHandle,
    error,
    isDisabled,
  } = props;

  return (
    <Draggable
      key={`${stepIndex}-${index}`}
      draggableId={`${stepIndex}-${index}`}
      index={index}
    >
      {(provided) => (
        <div
          /*className={cn(styles.stepWrapper, snapshot.isDragging && styles.stepWrapper_isDragging)}*/
          className={cn(
            className,
            styles.choiceAnswer,
            isDisabled && styles.choiceAnswer_disabled,
          )}
          {...provided.draggableProps}
          /*key={key}*/
          ref={provided.innerRef}
        >
          <div
            className={cn(styles.handle, hideDragHandle && styles.noHandle)}
            {...provided.dragHandleProps}
            tabIndex={-1}
          >
            <FontAwesomeIcon icon={solid("grip-vertical")} />
          </div>
          {choiceType === "single" && <div className={styles.iconSingle} />}
          {choiceType === "multiple" && <div className={styles.iconMultiple} />}
          <InputText
            className={styles.input}
            value={value}
            error={error}
            onChange={onChange}
            placeholder={placeholder}
            isDisabled={isDisabled}
          />
          {!isDisabled && (
            <div className={styles.deleteButtonWrapper}>
              <button
                className={cn(
                  styles.deleteButton,
                  !onClickDelete && styles.noDeleteButton,
                )}
                type={"button"}
                onClick={onClickDelete}
                disabled={!onClickDelete}
                tabIndex={-1}
              >
                <FontAwesomeIcon icon={solid("times")} />
              </button>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}
