import React from "react";
import { cn } from "@sys42/utils";

// import { } from '../../system42'
import styles from "./styles.module.css";

export function PageSelector(props) {
  const { className, count, page, onChange, ...containerProps } = props;

  function handleClickNext() {
    onChange(page + 1);
  }

  function handleClickPrevious() {
    onChange(page - 1);
  }

  return (
    <div className={cn(className, styles.pageSelector)} {...containerProps}>
      <button
        className={styles.previous}
        onClick={handleClickPrevious}
        disabled={page <= 1}
      >
        previous
      </button>
      <span>
        {page} of {count}
      </span>
      <button
        className={styles.next}
        onClick={handleClickNext}
        disabled={page >= count}
      >
        next
      </button>
    </div>
  );
}
