import React, { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "@sys42/utils";
import ReactSlider from "react-slider";

import { toHHMMSS } from "@/helpers-ts";
import { useClickOutside } from "@/hooks/useClickOutside";

import { PlayerProgress } from "./PlayerProgress";

import styles from "./styles.module.css";

const playbackRates = [1, 1.25, 1.4, 1.5, 1.75, 2];

export function PlayerControlBar(props) {
  const {
    className,
    mouseIdle,
    mouseOverPlayer,
    fixed,
    isFullscreen,
    playing,
    volume,
    playbackRate,
    duration,
    progress,
    thumbnailSprite,
    vttUrl,
    hasEnded,
    showSubtitles,
    subtitlesAvailable,
    airplayAvailable,
    onClickAirplay,
    onSeekTo,
    onChangeSeekThumbnailInfo,
    onChangePlaybackRate,
    onChangeVolume,
    onClickPlay,
    onClickPause,
    onClickMute,
    onClickForward5s,
    onClickBack5s,
    onClickRewind,
    onClickEnterFullscreen,
    onClickExitFullscreen,
    onClickToggleSubtitles,
    ...restProps
  } = props;

  const refSettingsControl = useRef();

  const [changingVolume, setChangingVolume] = useState(false);
  const [changingSettings, setChangingSettings] = useState(false);
  const [changingPlaybackRate] = useState(false);
  const [hidden, setHidden] = useState(false);

  const closeSettings = useCallback(() => {
    setChangingSettings(false);
  }, []);

  useClickOutside(refSettingsControl, closeSettings);

  useEffect(() => {
    if (mouseOverPlayer && (!mouseIdle || changingVolume)) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, [mouseOverPlayer, mouseIdle, changingVolume]);

  function handleVolumeBeforeChange() {
    setChangingVolume(true);
  }

  function handleVolumeAfterChange() {
    setChangingVolume(false);
  }

  function handleClickPlaybackRate() {
    const indexPlaybackRate = playbackRates.indexOf(playbackRate);
    if (indexPlaybackRate !== -1) {
      if (indexPlaybackRate < playbackRates.length - 1) {
        onChangePlaybackRate(playbackRates[indexPlaybackRate + 1]);
      } else {
        onChangePlaybackRate(playbackRates[0]);
      }
    } else {
      onChangePlaybackRate(playbackRates[0]);
    }
  }

  function handleClickButtonPlaybackRate(rate) {
    onChangePlaybackRate(rate);
  }

  function handleClickMute() {
    if (volume === 0) {
      onChangeVolume(1);
    } else {
      onChangeVolume(0);
    }
  }

  function handleClickButtonSettings() {
    setChangingSettings(!changingSettings);
  }

  function handleClickSettingsPopup() {
    closeSettings();
  }

  return (
    <div
      className={cn(
        className,
        styles.playerControlBar,
        fixed && styles.playerControlBar_fixed,
        !fixed && hidden && styles.playerControlBar_hidden,
      )}
      {...restProps}
    >
      <PlayerProgress
        className={styles.playerProgress}
        progress={progress}
        duration={duration}
        thumbnailSprite={thumbnailSprite}
        vttUrl={vttUrl}
        onSeekTo={onSeekTo}
        onChangeSeekThumbnailInfo={onChangeSeekThumbnailInfo}
      />
      <div className={styles.controls}>
        {!playing && !hasEnded && (
          <button className={styles.buttonPlay} onClick={onClickPlay}>
            Play
          </button>
        )}
        {playing && (
          <button className={styles.buttonPause} onClick={onClickPause}>
            Pause
          </button>
        )}
        {!playing && hasEnded && (
          <button className={styles.buttonRewind} onClick={onClickRewind}>
            Rewind
          </button>
        )}
        <div
          className={cn(styles.controlWithPopup, styles.controlPlaybackRate)}
        >
          <button
            className={styles.buttonPlaybackRate}
            onClick={handleClickPlaybackRate}
          >
            {playbackRate}x
          </button>
          <div
            className={cn(
              styles.controlPopup,
              changingPlaybackRate && styles.controlPopup_active,
              styles.controlPopup_playbackRate,
            )}
          >
            {playbackRates.map((rate, i) => (
              <button
                key={i}
                className={cn(
                  styles.buttonControlPopupPlaybackRate,
                  playbackRate === rate &&
                    styles.buttonControlPopupPlaybackRate_active,
                )}
                onClick={() => handleClickButtonPlaybackRate(rate)}
              >
                {" "}
                {rate}x
              </button>
            ))}
          </div>
        </div>
        {
          <button className={styles.buttonBack5s} onClick={onClickBack5s}>
            Back 5s
          </button>
        }
        {
          <button className={styles.buttonForward5s} onClick={onClickForward5s}>
            Forward 5s
          </button>
        }
        <div className={styles.controlWithPopup}>
          {
            <button
              className={cn(
                styles.buttonMute,
                volume === 0 && styles.buttonMute_muted,
                volume > 0 && volume <= 0.5 && styles.buttonMute_1,
                volume > 0.5 && styles.buttonMute_2,
              )}
              onClick={handleClickMute}
            >
              {volume === 0 ? "Unmute" : "Mute"}
            </button>
          }
          <div
            className={cn(
              styles.controlPopup,
              changingVolume && styles.controlPopup_active,
              styles.controlPopup_volume,
            )}
          >
            <ReactSlider
              className={styles.volumeSlider}
              thumbClassName={styles.volumeSliderThumb}
              trackClassName={styles.volumeSliderTrack}
              min={0}
              max={1}
              step={0.01}
              value={volume}
              orientation="vertical"
              invert={true}
              onChange={onChangeVolume}
              onBeforeChange={handleVolumeBeforeChange}
              onAfterChange={handleVolumeAfterChange}
              renderTrack={(props) => <div {...props} />}
              renderThumb={(props) => <div {...props} />}
            />
          </div>
        </div>
        <div className={styles.progressAndDuration}>
          {toHHMMSS((duration ?? 0) * progress, { trimLeadingZero: true })} /{" "}
          {toHHMMSS(duration ?? 0, { trimLeadingZero: true })}
        </div>
        <div
          className={cn(
            styles.settingsControl,
            !airplayAvailable &&
              !subtitlesAvailable &&
              styles.settingsControl_playbackRatesOnly,
          )}
          ref={refSettingsControl}
        >
          <button
            className={cn(
              styles.buttonSettings,
              changingSettings && styles.buttonSettings_active,
            )}
            onClick={handleClickButtonSettings}
          >
            Settings
          </button>

          <div
            onClick={handleClickSettingsPopup}
            className={cn(
              styles.settingsControlPopup,
              changingSettings && styles.settingsControlPopup_active,
            )}
          >
            <div className={styles.settingsPlaybackRates}>
              {playbackRates.map((rate, i) => (
                <button
                  key={i}
                  className={cn(
                    styles.buttonControlPopupPlaybackRate,
                    playbackRate === rate &&
                      styles.buttonControlPopupPlaybackRate_active,
                  )}
                  onClick={() => handleClickButtonPlaybackRate(rate)}
                >
                  {" "}
                  {rate}x
                </button>
              ))}
            </div>
            {airplayAvailable && (
              <button
                className={styles.buttonSettingsAirplay}
                onClick={onClickAirplay}
              >
                Airplay
              </button>
            )}
            {subtitlesAvailable && (
              <button
                className={styles.buttonSettingsSubtitles}
                onClick={onClickToggleSubtitles}
              >
                {showSubtitles ? "Hide subtitles" : "Show subtitles"}
              </button>
            )}
          </div>
        </div>

        {!isFullscreen && (
          <button
            className={styles.buttonEnterFullscreen}
            onClick={onClickEnterFullscreen}
          >
            Enter Fullscreen
          </button>
        )}
        {isFullscreen && (
          <button
            className={styles.buttonExitFullscreen}
            onClick={onClickExitFullscreen}
          >
            Exit Fullscreen
          </button>
        )}
      </div>
    </div>
  );
}
