import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class ErrorMessage extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, message, className, styles, icon, nomargin } = this.props;
    // nomargin removes the margin bottom, Todo: remove margin bottom and refactor code
    return (
      <div
        {...extractContainerProps(this.props)}
        className={cn(className, styles.root, nomargin && styles.nomargin)}
      >
        {icon && (
          <div className={styles.icon}>
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <div className={styles.message}>
          {message}
          {children}
        </div>
      </div>
    );
  }
}

export default ErrorMessage;
